import React from 'react';
import {
    TextField,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    Checkbox,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';
import CompetenceTable from "./CompetenceTable";

const FormField = React.memo(({
    field,
    sectionIndex,
    formState,
    handleInputChange,
    handleCheckboxChange,
    readOnly
}) => {

    let fieldValue = formState[`${sectionIndex}_${field.name}`] || '';

    switch (field.type) {
        case 'text':
        case 'textarea':
            return (
                <TextField
                    sx={{ mt: 3 }}
                    label={field.label}
                    multiline={field.type === 'textarea'}
                    rows={field.type === 'textarea' ? 4 : 1}
                    variant="outlined"
                    fullWidth
                    value={fieldValue}
                    inputProps={{ readOnly: readOnly }}
                    onChange={e => handleInputChange(sectionIndex, field.name, e.target.value)}
                />
            );

        case 'select':
            return (
                <FormControl fullWidth sx={{ mt: 3 }}>
                    <InputLabel id={`select-label-${field.name}`} >{field.label}</InputLabel>
                    <Select
                        labelId={`select-label-${field.name}`}
                        value={fieldValue}
                        label={field.label}
                        onChange={e => handleInputChange(sectionIndex, field.name, e.target.value)}
                    // displayEmpty
                    >
                        {field.options.map(option => (
                            <MenuItem key={option} value={option}>{option}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            );

        case 'checkbox':
            const res = formState[`${sectionIndex}_${field.name}`];
            return (
                field.options.map(option => (
                    <FormControlLabel
                        sx={{ mt: 3 }}
                        control={
                            <Checkbox
                                checked={res && res.indexOf(option) != -1}
                                onChange={e => handleCheckboxChange(sectionIndex, field.name, option, e.target.checked, true)}
                            />
                        }
                        label={option}
                    />
                ))
            );

        case 'radio':
            return (
                <FormControl sx={{ mt: 3 }}>
                    <RadioGroup
                        row
                        value={fieldValue}
                        onChange={e => handleInputChange(sectionIndex, field.name, e.target.value)}
                    >
                        {field.options.map(option => (
                            <FormControlLabel key={option} value={option} control={<Radio />} label={option} />
                        ))}
                    </RadioGroup>
                </FormControl>
            );
        case 'competences':
            return <CompetenceTable
                field={field}
                sectionIndex={sectionIndex}
                formState={formState}
                handleInputChange={handleInputChange}
            />

            break; // Unreachable code, but necessary to avoid a warning
        default:
            return null;
    }
}, areEqual);


function areEqual(prevProps, nextProps) {

    // Vérifier d'abord si les champs de base sont égaux.
    const prevValue = prevProps.formState[`${prevProps.sectionIndex}_${prevProps.field.name}`];
    const nextValue = nextProps.formState[`${nextProps.sectionIndex}_${nextProps.field.name}`];
    if (prevValue !== nextValue) {
        return false;
    }

    // Vérifier les champs spécifiques aux compétences si le type est 'competences'
    if (prevProps.field.type === 'competences') {
        for (let competence of prevProps.field.competences) {
            const prevCompetenceValue = prevProps.formState[`${prevProps.sectionIndex}_${prevProps.field.name}_${competence}`];
            const nextCompetenceValue = nextProps.formState[`${nextProps.sectionIndex}_${nextProps.field.name}_${competence}`];
            if (prevCompetenceValue !== nextCompetenceValue) {
                return false;
            }
        }
    }

    return true;
}

export default FormField;
