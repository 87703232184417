import React, {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useAuth} from "../Context/AuthContext";
import {Box, Button, Container, Paper, TextField, Typography} from "@mui/material";
import useTexts from "../hooks/useTexte";
import logoA from "../assets/logo/AZIMUTH.png";
import logoETN from "../assets/logo-etn.jpg";

// Définir le mot de passe de protection de la page ici

var accomptes = [

    {
        email: "emilie.boyer77820@gmail.com",
        codeSycomore: "366850",
        label: "Emilie Boyere"
    },
    {
        email: "pierroline@gmail.com",
        codeSycomore: "185688",
        label: "CG de emilie"
    },
    {
        email: "a.jamhouri@hotmail.fr",
        codeSycomore: "330794",
        label: "CD de Worf rozhenko"
    },
    {
        email: "jeanpierrebrisou60@gmail.com",
        codeSycomore: "257524",
        label: "CPRS de Worf rozhenko"
    },
    {
        email: "jfverhulst@me.com",
        codeSycomore: "16584575",
        label: "CG DE Jonanthan Archer"
    },
    {
        email: "hikarusulu@gmail.com",
        codeSycomore: "16703348",
        label: "Hikaru sulu -  Chef en Attente de validation"
    },
    {
        email: "lf.poumailloux@yahoo.fr",
        codeSycomore: "191949",
        label: "CG DE hikarusulu"
    },
    {
        email: "jeanlucpicard@gmail.com",
        codeSycomore: "16703349",
        label: "Jean-luc Picard -   Chef en liste d'attente"
    },
    {
        email: "gregoire.larreur@gmail.com",
        codeSycomore: "172452",
        label: "CG DE Jean-luc Picard "
    },
    {
        email: "Jonathanarcher@gmail.com",
        codeSycomore: "16703350",
        label: "Jonanthan Archer -   Chef en demande de dérogation"
    },
    {
        email: "jfverhulst@me.com",
        codeSycomore: "16584575",
        label: "CG DE Jonanthan Archer"
    },
    {
        email: "thibaultcourson@gmail.com",
        codeSycomore: "286606",
        label: "Mestre de camp"
    },
    {
        email: "pascale-felloni@scouts-europe.org",
        codeSycomore: "16506766",
        label: "Equipe CN support"
    },
]
const LoginDemoPage = () => {
    const {login} = useAuth();
    const [email, setEmail] = useState('');
    const [codeSycomore, setCodeSycomore] = useState('');
    const [password, setPassword] = useState('');
    const [pagePassword, setPagePassword] = useState(''); // Pour le mot de passe de la page
    const [isAuthorized, setIsAuthorized] = useState(false); // Vérifier l'accès
    let navigate = useNavigate();
    const location = useLocation();
    const {t} = useTexts();
    const from = location.state?.from?.pathname || '/';

    const onclickAccompte = (accompte) => {
        setEmail(accompte.email);
        setCodeSycomore(accompte.codeSycomore);
        setPassword('formation2025@@THIb');
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/login`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({email, code_sycomore: codeSycomore, password})
            });
            if (response.ok) {
                const {token} = await response.json();
                login(token);
                navigate(from, {replace: true});
            } else {
                alert('Échec de la connexion');
            }
        } catch (error) {
            console.error('Erreur de connexion', error);
        }
    };

    // Vérifier le mot de passe de la page
    const handlePagePasswordSubmit = () => {

        const body ={
            "password" : pagePassword
        }

        fetch(`${process.env.REACT_APP_API_URL}/api/password`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
            .then(response =>  setIsAuthorized(true))

            .catch(error => console.error("Erreur lors de la récupération des fonctions:", error));


    };

    // Si l'utilisateur n'est pas autorisé, afficher le formulaire de mot de passe de la page
    if (!isAuthorized) {
        return (
            <Container
                maxWidth="sm"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    minHeight: '100vh',
                    background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
                    padding: 4
                }}
            >
                <Paper elevation={6} sx={{padding: 4, borderRadius: 3}} onSubmit={handlePagePasswordSubmit}>
                    <Box sx={{textAlign: 'center', marginBottom: 2}}>
                        <img src={logoA} alt="Azimuth Logo" style={{width: '150px', marginBottom: '20px'}}/>
                        <Typography variant="h4" component="h1" gutterBottom>
                            {t("TITLE_APP")}
                        </Typography>
                        <Typography variant="h6" component="h2">
                            Entrez le mot de passe pour accéder à cette page
                        </Typography>
                    </Box>
                    <Box component="form" onSubmit={handlePagePasswordSubmit} sx={{mt: 2}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="pagePassword"
                            label="Mot de passe de la page"
                            id="pagePassword"
                            type="password"
                            value={pagePassword}
                            onChange={(e) => setPagePassword(e.target.value)}
                            sx={{backgroundColor: 'white', borderRadius: 1}}
                        />
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            sx={{mt: 3, mb: 2, padding: 1.5}}
                            onClick={handlePagePasswordSubmit}
                        >
                            Valider
                        </Button>
                    </Box>
                </Paper>
            </Container>
        );
    }

    // Si l'utilisateur est autorisé, afficher la page de connexion habituelle
    return (
        <Container
            maxWidth="sm"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                minHeight: '100vh',
                background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
                padding: 4
            }}
        >
            <Paper elevation={6} sx={{padding: 4, borderRadius: 3}}>
                <Box sx={{textAlign: 'center', marginBottom: 2}}>
                    <img src={logoA} alt="Azimuth Logo" style={{width: '150px', marginBottom: '20px'}}/>
                    <Typography variant="h4" component="h1" gutterBottom>
                        {t("TITLE_APP")}
                    </Typography>
                </Box>

                <Box component="form" sx={{mt: 2}}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={t("FIELD_EMAIL")}
                        value={email}
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{backgroundColor: 'white', borderRadius: 1}}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="codeSycomore"
                        label={t("FIELD_CODE_SYCOMORE")}
                        id="codeSycomore"
                        type="text"
                        value={codeSycomore}
                        onChange={(e) => setCodeSycomore(e.target.value)}
                        sx={{backgroundColor: 'white', borderRadius: 1}}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Mot de passe"
                        id="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        sx={{backgroundColor: 'white', borderRadius: 1}}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{mt: 3, mb: 2, padding: 1.5}}
                        onClick={handleLogin}
                    >
                        Connexion
                    </Button>
                </Box>
            </Paper>
            <Box sx={{textAlign: 'center', marginTop: 2}}>
                {accomptes.map((accompte) => (
                    <Typography
                        key={accompte.label}
                        variant="body2"
                        sx={{cursor: 'pointer', color: '#1e88e5', marginBottom: 1}}
                        onClick={() => onclickAccompte(accompte)}
                    >
                        {accompte.label}
                    </Typography>
                ))}
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 4, padding: 2}}>
                <Typography variant="body2" color="textSecondary" sx={{marginRight: 1}}>
                    Réalisation ETN Numérique
                </Typography>
                <img src={logoETN} alt="Logo ETN" style={{width: '100px'}}/>
            </Box>
        </Container>
    );
};

export default LoginDemoPage;
