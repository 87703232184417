import React from 'react';
import {
    Button,
    Typography,
    Box,
    Grid
} from '@mui/material';
import FormField from "./Fiche/FormField";

function MyFicheSection({ formData, formState, handleInputChange, handleCheckboxChange, chefFunction }) {
    return (
        <>
            {formData.sections.map((section, sectionIndex) => {
                if (Array.isArray(section.rules) && section.rules.length > 0) {
                    if (!section.rules.includes(chefFunction.fonction)) {
                        return null;
                    }
                }
                return (
                    <Grid item xs={12} key={section.title} sx={{m:1, p:2}}>
                        <Typography sx={{fontFamily: 'Ubuntu', fontWeight:"bold"}} variant="h6">{section.title}</Typography>
                        <Grid container spacing={2}>
                            {section.fields.map(field => (
                                <Grid item xs={12} sm={12} key={field.name}>
                                    <Box sx={{ overflow: 'hidden' }}>
                                        <FormField
                                            field={field}
                                            sectionIndex={sectionIndex}
                                            formState={formState}
                                            handleInputChange={handleInputChange}
                                            handleCheckboxChange={handleCheckboxChange}
                                            readOnly={false}
                                            chefFunction={chefFunction}
                                        />
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                );
            })}
        </>
    );
}

export default MyFicheSection;
