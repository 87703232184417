import React from 'react';
import { PortableText } from '@portabletext/react';
import { Container } from "@mui/material";
import { urlFor } from "../sanityClient";
import { motion } from 'framer-motion';
import './styles/ColBlock.css';

import PICTO_ECLAIREUR from '../assets/svg/pictos_branches/ECLAIREURS.svg';
import PICTO_ECLAIREUSE from '../assets/svg/pictos_branches/ECLAIREUSES.svg';
import PICTO_EQUIPIER_PILOTE from '../assets/svg/pictos_branches/EQUIPIERS_PILOTES.svg';
import EQUIPIERES_PILOTES from '../assets/svg/pictos_branches/EQUIPIERES_PILOTES.svg';
import PICTO_LOUVETEAUX from '../assets/svg/pictos_branches/LOUVETEAUX.svg';
import TERRITORIAL from '../assets/svg/pictos_branches/TERRITORIAL.svg';

const ColBlock = ({ title, textColor, backgroundColor, lines }) => {
    const styles = {
        progressionCMS: {
            backgroundColor: backgroundColor.hex,
            color: textColor.hex,
            padding: '20px',
        },
        title: {
            textAlign: 'center',
            color: textColor.hex,
        },
        title2: {
            textAlign: 'left',
            color: textColor.hex,
        },
        title3: {
            color: textColor.hex,
            fontSize: "30px",
            fontWeight: "800",
        },
        gridContainer: (numItems) => ({
            display: 'grid',
            gridTemplateColumns: `repeat(${numItems}, 1fr)`,
            gap: '20px',
            width: '100%',
        }),
        itemContainer: {
            alignItems: 'center',
        },
        container: {
            width: "90%",
            margin: "auto",
        },
        itemNumber: {
            width: '50px',
            height: '50px',
            borderRadius: '50%',
            backgroundColor: textColor.hex,
            color: backgroundColor.hex,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto 10px auto',
        },
        separator: {
            border: `1px solid ${textColor.hex}`,
            width: '80%',
            margin: '20px auto',
        },
        textContent: {
            textAlign: "left",
            fontSize: "16px",
            fontWeight: "500",
        },
    };

    const containerVariants = {
        hidden: { opacity: 0, scale: 0.8 },
        visible: { opacity: 1, scale: 1, transition: { duration: 0.5, staggerChildren: 0.3 } },
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    };

    return (
        <div className="progressionCMS" style={styles.progressionCMS}>
            <h2 style={styles.title}>{title}</h2>
            {lines.map((line, indexLine) => (
                <div key={indexLine} className='branches-details'>
                    <div className='branches-name'>
                        {line.lineTitle === 'Louvetisme' && <img src={PICTO_LOUVETEAUX} alt="Louvetisme" />}
                        {line.lineTitle === 'Eclaireurs' && <img src={PICTO_ECLAIREUR} alt="Eclaireure" />}
                        {line.lineTitle === 'Eclaireuses' && <img src={PICTO_ECLAIREUSE} alt="Eclaireuse" />}
                        {line.lineTitle === 'Route' && <img src={PICTO_EQUIPIER_PILOTE} alt="Equipier Pilote" />}
                        {line.lineTitle === 'Feu' && <img src={EQUIPIERES_PILOTES} alt="Equipière Pilote" />}
                        {line.lineTitle === 'Territoriale' && <img src={TERRITORIAL} alt="Territorial" />}
                        <h3 style={styles.title2}>{line.lineTitle}</h3>
                    </div>
                    <div style={styles.container} className='section-formations'>
                        <motion.div
                            className="colitemwrap"
                            style={styles.gridContainer(line.items.length)}
                            initial="hidden"
                            animate="visible"
                            variants={containerVariants}
                        >
                            {line.items.map((item, index) => (
                                <motion.div
                                    key={index}
                                    className="colitem"
                                    style={styles.itemContainer}
                                    variants={itemVariants}
                                >
                                    {item.image && <img className="imgfix" src={urlFor(item.image.asset).width(520).height(340).fit('crop').url()} alt={item.title} />}
                                    <h4 style={styles.title3}>{item.title}</h4>
                                    <p style={styles.textContent}><PortableText value={item.blockText} /></p>
                                </motion.div>
                            ))}
                        </motion.div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ColBlock;
