import React, {useEffect, useState} from 'react';
import {
    Button,
    Typography,
    Box,
    Grid,
    CircularProgress
} from '@mui/material';
import {useNavigate} from "react-router-dom";
import FormField from "./FormField";
import {useFetchWithToken} from "../../hooks/useFetchWithToken";
import {useSystem} from "../../Context/System";
import useTexts from "../../hooks/useTexte";
import {useAuth} from "../../Context/AuthContext";
import MyFicheSection from "../MyFicheSection";
import ImageSecure from "../Core/ImageSecure";
import {downloadDocument} from "../../Utils/downloadDocument";

function MaFiche({id, onClose}) {
    const {token, currentFunction, functionOptions} = useAuth();
    const chefFunction = functionOptions.find(func => func.id == currentFunction);
    const navigate = useNavigate();
    const fetchWithToken = useFetchWithToken();
    const {getConfiguration} = useSystem();
    const [isLoadingDetail, setIsLoadingDetail] = useState(true);
    const [isLoadingAttachement, setIsLoadingAttachement] = useState(true);
    const [attachments, setAttachments] = useState([]);
    const sanitaireUrl = getConfiguration('SANITAIRE_URL');
    const {t} = useTexts();
    const [formData, setFormData] = useState();
    const [formState, setFormState] = useState({});

    const handleBack = () => {
        navigate('/formations');
    };

    const handleFileUpload = async (file, type) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('id', id);

        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/form/voeu/${id}/savedoc/${type}`;
            await fetchWithToken(apiUrl, "POST", formData, 1);
            loadAttachement();
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const loadWishesDetail = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/form/voeu/${id}`;
            const data = await fetchWithToken(apiUrl);

            const newForm = { "sections": [] };
            for (const key in data.data) {
                const section = data.data[key];
                newForm.sections.push(section);
            }
            setFormData(newForm);
            setFormState(data.answer);
            setIsLoadingDetail(false);
        } catch (error) {
            console.error('Erreur lors du chargement des vœux:', error);
        }
    };

    const loadAttachement = async () => {
        try {
            setIsLoadingAttachement(true);
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/form/voeu/${id}/attachement`;
            const data = await fetchWithToken(apiUrl);
            setAttachments(data);
            setIsLoadingAttachement(false);
        } catch (error) {
            console.error('Erreur lors du chargement des vœux:', error);
        }
    };

    useEffect(() => {
        loadWishesDetail();
        loadAttachement();
    }, []);

    const handleDownloadAttachment = (attachmentPath) => {
        downloadDocument(attachmentPath, token, {"type": "stagaire", "formationId": id});
    };

    const handleDeleteAttachment = async (attachmentId) => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/attachment/${attachmentId}`;
            await fetchWithToken(apiUrl, "DELETE");
            loadAttachement();
        } catch (error) {
            console.error('Error deleting attachment:', error);
            loadAttachement();
        }
    };

    const handleCheckboxChange = (sectionIndex, fieldName, optionValue, isChecked, isMultiple) => {
        if (isMultiple) {
            setFormState(prevState => {
                const existingValues = prevState[`${sectionIndex}_${fieldName}`] || [];
                return {
                    ...prevState,
                    [`${sectionIndex}_${fieldName}`]: isChecked ? [...existingValues, optionValue] : existingValues.filter(value => value !== optionValue)
                };
            });
        } else {
            setFormState(prevState => ({
                ...prevState,
                [`${sectionIndex}_${fieldName}`]: isChecked
            }));
        }
    };

    const handleInputChange = (sectionIndex, fieldName, value) => {
        setFormState(prevState => ({
            ...prevState,
            [`${sectionIndex}_${fieldName}`]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/form/voeu/${id}/savesanswer`;
            const body = { formData: formState };
            await fetchWithToken(apiUrl, "POST", body);
        } catch (error) {
            console.error('Erreur lors de la soumission du formulaire:', error);
        }
    };

    if (!formData || isLoadingAttachement || isLoadingDetail) {
        return <Box sx={{display: 'flex', justifyContent: 'center', p: 3}}><CircularProgress/></Box>;
    }

    const photoAttachment = attachments.find(attachment => attachment.type === 'photo');
    const docAttachment = attachments.find(attachment => attachment.type === 'doc');
    const sectionFilter = formData.sections.filter(section => {
        if (Array.isArray(section.rules) && section.rules.length > 0) {
            return section.rules.includes(chefFunction.fonction);
        }
        return true;
    });
    const dataImage = { "formation_id": id, "auth": "stagaire" };

    return (
        <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    {photoAttachment && (
                        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2}}>
                            <ImageSecure
                                path={photoAttachment.path}
                                params={dataImage}
                                style={{width: '100px', height: 'auto', marginBottom: '8px'}}
                            />
                            <Button onClick={() => handleDeleteAttachment(photoAttachment.id)} variant="outlined">{t("TEXT_DELETE_PHOTO")}</Button>
                        </Box>
                    )}
                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <input
                            accept="image/*"
                            type="file"
                            onChange={e => handleFileUpload(e.target.files[0], 'photo')}
                            style={{display: 'none'}}
                            id="upload-photo"
                        />
                        <label htmlFor="upload-photo">
                            <Button variant="contained" component="span">{t("TEXT_ADD_PHOTO")}</Button>
                        </label>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    {docAttachment && (
                        <Box sx={{display: 'flex', flexDirection: 'column', mb: 2}}>
                            <Typography sx={{fontFamily: 'Ubuntu'}} variant="h6">{docAttachment.nom}</Typography>
                            <Button onClick={() => handleDownloadAttachment(docAttachment.path)} variant="outlined">{t("TEXT_DOWNLOAD_DOC")}</Button>
                            <Button onClick={() => handleDeleteAttachment(docAttachment.id)} variant="outlined">{t("TEXT_DELETE_DOC")}</Button>
                        </Box>
                    )}
                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <input
                            accept=".pdf,.doc,.docx"
                            type="file"
                            onChange={e => handleFileUpload(e.target.files[0], 'doc')}
                            style={{display: 'none'}}
                            id="upload-fiche"
                        />
                        <label htmlFor="upload-fiche">
                            <Button variant="contained" component="span">{t("TEXT_ADD_FICHE")}</Button>
                        </label>
                        {t("TEXT_DOWNLOAD_DOC")}
                        <p>{t("URL_FICHE_IN_SYCOMO_TEXT")} <a target={'_blank'} href={sanitaireUrl}>Ici</a></p>
                    </Box>
                </Grid>

                        <MyFicheSection formData={formData} formState={formState}
                                        handleInputChange={handleInputChange}
                                        handleCheckboxChange={handleCheckboxChange}
                                        chefFunction={chefFunction}
                        />
            </Grid>
            <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 3}}>
                <Button type="submit" variant="contained" sx={{mr: 2}}>{t("BUTTON_VALIDER")}</Button>
                <Button variant="contained" onClick={onClose}>{t("BUTTON_RETOUR")}</Button>
            </Box>
        </Box>
    );
}

export default MaFiche;
