import React, {useEffect, useState} from 'react';
import {
    Button,
    Grid,
    Container,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Toolbar,
    AppBar,
    CircularProgress,
    Box,

} from '@mui/material';
import {useAuth} from '../Context/AuthContext';
import {useSystem} from '../Context/System';
import {branchOptions} from '../Utils/BranchOption';
import {LevelOptions} from '../Utils/LevelOption';
import FormationForm from "../composant/Formation/FormationForm";
import {useFetchWithToken} from "../hooks/useFetchWithToken"
import FormationAdminItemTable from "../composant/Formation/FormationAdminItemTable";
import useTexts from "../hooks/useTexte";
import withAdminRole from "../hoc/withAdminRole";
import withAdminOrFormationRole from "../hoc/withAdminOrFormationRole";
import useRoles from "../hooks/useRoles";

const FormationAdminListPage = () => {
    const {isLoadingFunction, functionOptions, section, currentFunction} = useAuth();
    const [selectedType, setSelectedType] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const {t} = useTexts();
    const [selectedBranche, setSelectedBranche] = useState('');
    const [wishes, setWishes] = useState([]);
    const fetchWithToken = useFetchWithToken();

    const chefFunction = functionOptions.find(func => func.id == currentFunction);
    const roles = useRoles(chefFunction ? chefFunction.fonction : "");

    const isAdministrator = roles.includes('Administrator');

    const [questionnaires, setQuestionnaires] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [page, setPage] = useState(1);

    const {datesGroupe} = useSystem();



    const handleChangeDate = (event) => {
        setSelectedDate(event.target.value);
        setPage(1);
    };
    const handleChangeType = (event) => {
        setSelectedType(event.target.value);
        setPage(1);
    };
    const addFormation = async (wish) => {
        try {
            let apiUrl = `${process.env.REACT_APP_API_URL}/api/formation`;
            const data = await fetchWithToken(apiUrl, 'POST', wish);
            loadWishes();
            return data;
        } catch (error) {
            console.error('Erreur lors de l\'ajout de la formation:', error);
        }
    };

    const fetchQuestionnaires = async () => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/form`;
        const data = await fetchWithToken(apiUrl);
        setQuestionnaires(data);
        setLoading(false);
    };
    const handleChangeBranche = (event) => {
        setSelectedBranche(event.target.value);
        setPage(1);
    };
    const loadWishes = async () => {
        try {
            const body = {
                branche: selectedBranche,
                date:selectedDate,
                level: selectedType.key,
                page
            }
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin`;
            const data = await fetchWithToken(apiUrl, 'POST', body);
            setWishes(data.formations);
            setTotalPages(data.totalPages);
        } catch (error) {
            console.error('Erreur lors du chargement des formations:', error);
        }
    };



    useEffect(() => {

        fetchQuestionnaires();
        loadWishes();

    }, []);

    useEffect(() => {
        loadWishes();
    }, [selectedType, selectedBranche, page, selectedDate]);


    if (!isLoadingFunction || loading) {
        return <Box sx={{display: 'flex', justifyContent: 'center', p: 3}}>
            <CircularProgress/>
        </Box>;
    }

    const nextPage = () => {
        setPage(page + 1);
    };

    const prevPage = () => {
        if (page > 1) setPage(page - 1);
    };

    return (
        <Container maxWidth="xl">
            <AppBar position="static" color="default" sx={{top: 64}}>
                <Toolbar>
                    <Container maxWidth="xl" sx={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>
                        <FormControl variant="outlined" sx={{m: 1, minWidth: 240}}>
                            <InputLabel>{t("TEXT_TYPE_FORMATION")}</InputLabel>
                            <Select
                                value={selectedType} onChange={handleChangeType} label={t("TEXT_TYPE_FORMATION")}
                            >
                                <MenuItem value="">
                                    <em>{t("INPUT_SELECT_ALL")}</em>
                                </MenuItem>
                                {LevelOptions.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {isAdministrator && (
                        <FormControl variant="outlined" sx={{m: 1, minWidth: 240}}>
                            <InputLabel>{t("TEXT_COL_BRANCHE")}</InputLabel>
                            <Select
                                value={selectedBranche}
                                onChange={handleChangeBranche}
                                label={t("TEXT_COL_BRANCHE")}
                            >
                                <MenuItem value="">
                                    <em>{t("INPUT_SELECT_ALL")}</em>
                                </MenuItem>
                                {branchOptions.map((option) => (
                                    <MenuItem key={option.key} value={option.key}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        )}
                        <FormControl variant="outlined" sx={{m: 1, minWidth: 240}}>
                            <InputLabel>Dates</InputLabel>
                            <Select
                                value={selectedDate} onChange={handleChangeDate} label={t("TEXT_TYPE_FORMATION")}
                            >
                                <MenuItem value="">
                                    <em>{t("INPUT_SELECT_ALL")}</em>
                                </MenuItem>
                                {datesGroupe.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.nom}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Container>
                </Toolbar>
            </AppBar>
            <Toolbar/>
            <Grid container spacing={2}>


                {wishes.map((wish, index) => (
                    <FormationAdminItemTable
                        key={index}
                        wish={wish}
                        index={index}
                        questionnaires={questionnaires}
                        functionOptions={functionOptions}
                        addFormation={addFormation}
                        loadWishes={loadWishes}
                    />
                ))}

            </Grid>

            <AppBar position="fixed" color="default" sx={{top: 'auto', bottom: 0}}>
                <Toolbar>
                    <Container maxWidth="xl" sx={{display: 'flex', justifyContent: 'space-between'}}>

                        <Box>
                            <Button onClick={prevPage} disabled={page === 1}>
                                {t("BUTTON_PAGE_PREV")}
                            </Button>
                            <Button onClick={nextPage} disabled={page === totalPages}>
                                {t("BUTTON_PAGE_NEXT")}
                            </Button>
                        </Box>
                    </Container>
                </Toolbar>
            </AppBar>


        </Container>
    );
};
export default withAdminOrFormationRole(FormationAdminListPage);

