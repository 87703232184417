import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardContent,
    Grid,
    Container,
    Box,
    CircularProgress,
    Modal,
    TextField,
    Typography
} from '@mui/material';

import { Link } from 'react-router-dom';

import { useFetchWithToken } from "../hooks/useFetchWithToken";

const Forms = () => {
    const fetchWithToken = useFetchWithToken();

    const [forms, setForms] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [newFormName, setNewFormName] = useState('');
    const [selectedFormId, setSelectedFormId] = useState(null);
    const loadForms = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/form`;
            const data = await fetchWithToken(apiUrl);
            setForms(data);
            setIsLoading(false);
        } catch (error) {
            console.error('Erreur lors du chargement des formulaires:', error);
        }
    };
    useEffect(() => {


        loadForms();
    }, []);

    const handleOpen = (formId) => {
        setSelectedFormId(formId);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setNewFormName('');
        setSelectedFormId(null);
    };

    const handleDuplicate = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/form/${selectedFormId}/duplicate`;
            const body = {
                name:newFormName
            }
            const data =    await fetchWithToken(apiUrl, "POST", body)
            // Recharger les formulaires après duplication
            loadForms();
            handleClose();
        } catch (error) {
            console.error('Erreur lors de la duplication du formulaire:', error);
        }
    };

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Container maxWidth="md">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h1>Liste des questionnaires utilisateurs</h1>
                    <div style={{ margin: '20px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
                        {forms.length > 0 ? (
                            forms.map((form, index) => (
                                <Card
                                    key={index}
                                    variant="outlined"
                                    sx={{ mb: 2, border: '1px solid #ccc', borderRadius: '4px' }}
                                >
                                    <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Box flexGrow={1} sx={{ marginRight: '10px' }}>
                                            {form.name}
                                        </Box>
                                        <Box>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                component={Link}
                                                to={`/creer-template/${form.id}`}
                                                sx={{ marginRight: '10px' }}
                                            >
                                                Modifier
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                onClick={() => handleOpen(form.id)}
                                            >
                                                Dupliquer
                                            </Button>
                                        </Box>
                                    </CardContent>
                                </Card>
                            ))
                        ) : (
                            <p>Aucun questionnaire à afficher.</p>
                        )}
                    </div>
                    <Button
                        component={Link}
                        to={"/creer-template"}
                        variant="outlined"
                        color="primary"
                    >
                        Ajouter un questionnaire
                    </Button>
                </Grid>
            </Grid>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography id="modal-title" variant="h6" component="h2">
                        Dupliquer le formulaire
                    </Typography>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Nom du nouveau formulaire"
                        type="text"
                        fullWidth
                        value={newFormName}
                        onChange={(e) => setNewFormName(e.target.value)}
                    />
                    <Button onClick={handleDuplicate} variant="contained" color="primary" sx={{ mt: 2 }}>
                        Dupliquer
                    </Button>
                </Box>
            </Modal>
        </Container>
    );
};

export default Forms;
