import React from 'react';
// import noResultImage from '../assets/noresult.png'; Ancienne image

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
    },
    bannerImage: {
        width: '100%',
        maxHeight: '200px', // Ajustez cette valeur pour changer la hauteur de la bannière
        objectFit: 'cover', // Permet à l'image de s'adapter à la largeur tout en restant proportionnelle
    },
    text: {
        marginTop: '20px',
        fontSize: '24px',
        color: '#333',
    },
};

const NoResultFound = ({text}) => {
    return (
        <div style={styles.container}>
            <p style={styles.text}>{text}</p>
        </div>
    );
};

export default NoResultFound;
