import React, { Suspense, useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import client, { urlFor } from '../sanityClient';

import { PortableText } from '@portabletext/react';

import { useSpring, animated } from '@react-spring/web';

import { Box, Grid, Typography } from '@mui/material';
import './styles/CMSTop.css';

const ContentBlockRenderer = React.lazy(() => import('../composant/ContentBlockRenderer'));

function CMSTop({ cmsData }) {

    const heroTitleAnimation = useSpring({
        from: { opacity: 0, transform: 'translateY(-200px)' },
        to: { opacity: 1, transform: 'translateY(0)' },
        delay: 100,
    });

    const heroDescAnimation = useSpring({
        from: { opacity: 0, transform: 'translateY(200px)' },
        to: { opacity: 1, transform: 'translateY(0)' },
        delay: 100,
    });

    const heroImageAnimation = useSpring({
        from: { opacity: 0, transform: 'translateX(150px)' },
        to: { opacity: 1, transform: 'translateX(0)' },
        delay: 100,
    });

    return (
        <div className="cmspageWrap">
            {cmsData.imageHero ? (
                <div className="hero">
                    <Grid container spacing={0}>
                        <Grid item xs={12} md={12}>
                            <animated.div style={heroTitleAnimation}>
                                <Typography sx={{ fontFamily: "ubuntu" }} variant="h1" component="h1" gutterBottom className="cmsHerotitle">
                                    {cmsData.title}
                                </Typography>
                            </animated.div>
                            <animated.div style={heroDescAnimation}>
                                <Typography sx={{ fontFamily: "ubuntu" }} variant="body1" component="p" className="cmsHeroDesctitle">
                                    <PortableText value={cmsData.description} />
                                </Typography>
                            </animated.div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <img
                            className='img-hero-accueil'
                            src={urlFor(cmsData.imageHero).url()}
                            alt=""
                        />
                    </Grid>
                </div>
            ) : (
                <div className="hero">
                    <Grid container spacing={0}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                textAlign: 'center',
                                p: 1,
                                width: '100%',
                            }}
                        >
                            <Typography
                                sx={{ fontFamily: 'ubuntu', color: '#B71234', fontSize:"3rem" }}
                                variant="h1"
                                component="h1"
                                gutterBottom
                                className="cmsHerotitle"
                            >
                                {cmsData.title}
                            </Typography>
                        </Box>
                    </Grid>
                </div>
            )}
            {cmsData.content?.map((block, index) => (
                <ContentSection key={index} block={block} />
            ))}
        </div>
    );
}

const ContentSection = ({ block }) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const sectionAnimation = useSpring({
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateY(0)' : 'translateY(50px)',
        config: { duration: 500 },
    });

    const [loadContent, setLoadContent] = useState(false);

    useEffect(() => {
        if (inView) {
            setLoadContent(true);
        }
    }, [inView]);

    return (
        <animated.div ref={ref} style={sectionAnimation}>
            {loadContent ? (
                <Suspense fallback={<div>Loading...</div>}>
                    <ContentBlockRenderer block={block} />
                </Suspense>
            ) : (
                <div>Loading...</div>
            )}
        </animated.div>
    );
};

export default CMSTop;
