import React, { useState } from "react";
import { Box, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, TextField } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

function FieldOptionsEditor({ options, onChange, label }) {
    const [newOption, setNewOption] = useState('');

    const handleAddOption = () => {
        if (newOption.trim() !== '' && !options.includes(newOption)) {
            onChange([...options, newOption]);
            setNewOption('');
        }
    };

    const handleRemoveOption = (optionToRemove) => {
        onChange(options.filter(option => option !== optionToRemove));
    };

    const handleMoveUp = (index) => {
        if (index > 0) {
            const newOptions = [...options];
            [newOptions[index], newOptions[index - 1]] = [newOptions[index - 1], newOptions[index]];
            onChange(newOptions);
        }
    };

    const handleMoveDown = (index) => {
        if (index < options.length - 1) {
            const newOptions = [...options];
            [newOptions[index], newOptions[index + 1]] = [newOptions[index + 1], newOptions[index]];
            onChange(newOptions);
        }
    };

    return (
        <Box>
            <TextField
                label={label}
                value={newOption}
                onChange={(e) => setNewOption(e.target.value)}
                margin="normal"
                variant="outlined"
                size="small"
                fullWidth
            />
            <IconButton onClick={handleAddOption} color="primary" aria-label="add">
                <AddCircleOutlineIcon/>
            </IconButton>
            <List dense>
                {options.map((option, index) => (
                    <ListItem key={index}>
                        <ListItemText primary={option} />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="move up" onClick={() => handleMoveUp(index)}>
                                <ArrowUpwardIcon/>
                            </IconButton>
                            <IconButton edge="end" aria-label="move down" onClick={() => handleMoveDown(index)}>
                                <ArrowDownwardIcon/>
                            </IconButton>
                            <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveOption(option)}>
                                <DeleteIcon/>
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
}

export default FieldOptionsEditor;
