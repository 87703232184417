import React, {useEffect, useState} from 'react';
import {
    Button,
    Grid,
    Container,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Toolbar,
    AppBar,
    Box,
    CircularProgress,
    TextField,
    Checkbox,
    CardContent,
    Card, DialogTitle, DialogContent, Dialog, Typography
} from '@mui/material';
import {useFetchWithToken} from '../hooks/useFetchWithToken';
import {branchOptions} from '../Utils/BranchOption';
import {LevelOptions} from '../Utils/LevelOption';
import ErrorModal from "../composant/Modal/ErrorModal";
import ValidateModal from "../composant/Modal/ValidateModal";
import useTexts from "../hooks/useTexte";
import ConfirmeModal from "../composant/Modal/ConfirmeModal";
import {style} from "../Utils/style";
import {useAuth} from "../Context/AuthContext";
import DerogationItem from "../composant/Wish/DerogationItem";
import withAdminRole from "../hoc/withAdminRole";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import SearchModal from "../composant/Search/SearchModal";
import {useSystem} from "../Context/System";
import StructureAutocomplete from "../composant/StructureAutocomplete";
import WishItem from "../composant/Wish/WishItem";

const VoeuxListPage = ({onSelect}) => {
    const [showFilters, setShowFilters] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedPersonne, setSselectedPersonne] = useState(false);
    const [selectedType, setSelectedType] = useState('');
    const [selectedBranche, setSelectedBranche] = useState('');

    const [selectedStruct, setSelectedStruct] = useState('');




    const [wishes, setWishes] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [page, setPage] = useState(1);
    const fetchWithToken = useFetchWithToken();
    const {t} = useTexts();
    const {isLoadingFunction, childUnite} = useAuth();

    const handleChangeType = (event) => {
        setSelectedType(event.target.value);
        setPage(1);

    };

    const handleChangeStruct = (value) => {
        setSelectedStruct(value);
        setPage(1);

    };
    const handleChangeBranche = (event) => {
        setSelectedBranche(event.target.value);
        setPage(1);

    };



    const loadWishes = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/geetmystructWish`;
            const body = {
                branche: selectedBranche,
                structure: selectedStruct,
                level: selectedType.key,
                page
            }
            const data = await fetchWithToken(apiUrl, 'POST', body);
           setWishes(data.wish);
            setTotalPages(data.totalPages);

        } catch (error) {
            console.error('Erreur lors du chargement des vœux:', error);
            setWishes([]);
        }
    };

    useEffect(() => {
        loadWishes();
    }, []);
    useEffect(() => {
        loadWishes();
    }, [selectedType, selectedBranche, page, selectedStruct]);


    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const nextPage = () => {
        setPage(page + 1);
    };

    const prevPage = () => {
        if (page > 1) setPage(page - 1);
    };

    const formatSearchUser = (user) => {
        return user.firstname + " " + user.lastname
    };


    if (!isLoadingFunction) {
        return (
            <Box sx={{display: 'flex', justifyContent: 'center', p: 3}}>
                <CircularProgress/>
            </Box>
        );
    }


    return (
        <Container maxWidth="xl">
            <AppBar position="static" color="default" sx={{top: 10, boxShadow: "none", background: "none"}}>
                <Toolbar sx={{border: "non"}}>
                    <Container maxWidth="xl"
                               sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Button
                            variant="outlined"


                            onClick={() => setShowFilters(!showFilters)}
                            startIcon={<FilterListIcon/>}
                            sx={{minWidth: 100, color: style.bleu1}}
                        >
                            {showFilters ? t("TEXT_HIDE_FILTERS") : t("TEXT_SHOW_FILTERS")}
                        </Button>

                    </Container>
                </Toolbar>
                {showFilters && (
                    <Toolbar>
                        <Container maxWidth="xl"
                                   sx={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 2}}>
                            <FormControl variant="outlined" sx={{m: 1, minWidth: 240, flex: '1 1 auto'}}>
                                <InputLabel>{t("TEXT_COL_TYPE")}</InputLabel>
                                <Select
                                    value={selectedType}
                                    onChange={handleChangeType}
                                    label={t("TEXT_COL_TYPE")}
                                >
                                    <MenuItem value="">
                                        <em>{t("INPUT_SELECT_ALL")}</em>
                                    </MenuItem>
                                    {LevelOptions.map((option) => (
                                        <MenuItem key={option.key} value={option}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl variant="outlined" sx={{m: 1, minWidth: 120, flex: '1 1 auto'}}>
                                <InputLabel>{t("LIST_FORMATION_BRANCHE")}</InputLabel>
                                <Select
                                    value={selectedBranche}
                                    onChange={handleChangeBranche}
                                    label="Branche"
                                >
                                    <MenuItem value="">
                                        <em>{t("INPUT_SELECT_ALL")}</em>
                                    </MenuItem>
                                    {branchOptions.map((option) => (
                                        <MenuItem key={option.key} value={option.key}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <StructureAutocomplete data={childUnite} selectedData={selectedStruct}
                                                   handleChange={handleChangeStruct}
                                                   label={t("LIST_FORMATION_STRUCT")}
                            />

                        </Container>
                    </Toolbar>
                )}
            </AppBar>

            <Toolbar/>


            <Grid container spacing={2}>

                <Grid item xs={12}>
                    {wishes.map((wish, index) => (
                        <Card sx={{width: '100%', marginBottom: 2}}
                              key={wish.voeu_id}> {/* Ajusté pour occuper la pleine largeur */}
                            <CardContent
                                sx={{
                                    display: 'flex',
                                    flexDirection: {xs: 'column', sm: 'row'},
                                    alignItems: 'center',
                                    gap: 2
                                }}>

                                <WishItem wish={wish}/>
                            </CardContent>
                        </Card>
                    ))}
                </Grid>
            </Grid>
            <AppBar position="fixed" color="default" sx={{top: 'auto', bottom: 0}}>
                <Toolbar>
                    <Container maxWidth="xl" sx={{display: 'flex', justifyContent: 'space-between'}}>

                        <Box>
                            <Button onClick={prevPage} disabled={page === 1}>
                                {t("BUTTON_PAGE_PREV")}
                            </Button>
                            <Button onClick={nextPage} disabled={page === totalPages}>
                                {t("BUTTON_PAGE_NEXT")}
                            </Button>
                        </Box>
                    </Container>
                </Toolbar>
            </AppBar>


        </Container>
    );
};
export default VoeuxListPage;
