// utils/downloadDocument.js
export const downloadDocument = (filename, token, additionalParams ) => {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = `${process.env.REACT_APP_API_URL}/document/${filename}`;
    form.style.display = 'none';

    const tokenInput = document.createElement('input');
    tokenInput.type = 'hidden';
    tokenInput.name = 'token';
    tokenInput.value = token;

    // const functionIdInput = document.createElement('input');
    // functionIdInput.type = 'hidden';
    // functionIdInput.name = 'function_id';
    // functionIdInput.value = function_id;

    form.appendChild(tokenInput);
    // form.appendChild(functionIdInput);
    // Append additional params
    Object.keys(additionalParams).forEach(key => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = additionalParams[key];
        form.appendChild(input);
    });

    document.body.appendChild(form);
 form.submit();
    document.body.removeChild(form);
};
