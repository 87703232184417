import React, { useEffect, useState } from 'react';
import {
    Button,
    Container,
    List,
    ListItem,
    ListItemText,
    TextField,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';
import { useFetchWithToken } from '../hooks/useFetchWithToken';
import {useAuth} from "../Context/AuthContext";
import useRoles from "../hooks/useRoles";
import useTexte from "../hooks/useTexte"; // Assurez-vous que le chemin d'importation est correct
import withAdminRole from "../hoc/withAdminRole"; // Assurez-vous que le chemin d'importation est correct

const ConfigEditor = () => {
    const [texts, setTexts] = useState([]);
    const [newKey, setNewKey] = useState('');
    const [newText, setNewText] = useState('');
    const [editText, setEditText] = useState({ id: '', key: '', text: '' });
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const fetchWithToken = useFetchWithToken();



    const fetchTexts = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/configuration`;
            const data = await fetchWithToken(apiUrl, "GET");
            setTexts(data);
        } catch (error) {
            console.error(error);
        }
    };


    const updateText = async (id, key, text) => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/configuration/`;
            const updatedTextData = await fetchWithToken(apiUrl, 'PUT', { key, text });
            setTexts(texts.map(t => t.key === key ? updatedTextData : t));
            setIsDialogOpen(false);
        } catch (error) {
            console.error(error);
        }
    };

    const handleDialogOpen = (text) => {
        setEditText(text);
        setIsDialogOpen(true);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    const handleDialogSave = () => {
        updateText(editText.id, editText.KEY, editText.value);
    };

    useEffect(() => {
        fetchTexts();
    }, []);

    return (
        <Container>
            <List>
                {texts.map(text => (
                    <ListItem key={text.id}>
                        <ListItemText primary={`${text.description}: ${text.value}`} />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleDialogOpen(text)}
                        >
                            Edit
                        </Button>
                    </ListItem>
                ))}
            </List>



            <Dialog open={isDialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Edit Text</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Modify the text and key as needed.
                    </DialogContentText>
                    <TextField
                        margin="dense"
                        label="Text"
                        fullWidth
                        value={editText.value}
                        onChange={e => setEditText({ ...editText, value: e.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="secondary">
                        Annuler
                    </Button>
                    <Button onClick={handleDialogSave} color="primary">
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default withAdminRole(ConfigEditor);
