import {
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@mui/material';

const CompetenceTable = ({ field, sectionIndex, formState, handleInputChange, readonly }) => {
    return (
        <TableContainer component={Paper} sx={{ mb: 2 }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Competence</TableCell>
                        {field.options.map(option => (
                            <TableCell key={option}>{option}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {field.competences.map(competence => {
                        let fieldValueComptence ='';

                        if (formState) {
                             fieldValueComptence = formState[`${sectionIndex}_${field.name}_${competence}`] || '';
                        }
                        return (
                            <TableRow key={competence}>
                                <TableCell>{competence}</TableCell>
                                {field.options.map(option => (
                                    <TableCell key={option}>
                                        <FormControlLabel
                                            value={option}
                                            control={<Radio
                                                checked={fieldValueComptence === option}
                                                onChange={!readonly ? e => handleInputChange(sectionIndex, `${field.name}_${competence}`, e.target.value) : null}
                                                disabled={readonly}
                                            />}
                                            label=""
                                        />
                                    </TableCell>
                                ))}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default CompetenceTable;
