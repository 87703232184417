import React, {Suspense, useEffect, useState} from 'react';
import {
    Button,
    TextField,
    FormControl,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Select,
    InputLabel,
    MenuItem,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    DialogActions, Grid, Chip, OutlinedInput,

} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import PreviewIcon from '@mui/icons-material/Preview';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';

import AdminFormField from "../composant/Fiche/AdminFormField"
import {useNavigate, useParams} from 'react-router-dom';
import {useFetchWithToken} from "../hooks/useFetchWithToken";
import useTexts from "../hooks/useTexte";
import FormField from "../composant/Fiche/FormField";
import FormFieldView from "../composant/Fiche/FormFieldView";
import {positions} from "../Utils/Positions";

function FormBuilder({initialData, isModal, closeModal, setHasUnsavedChangesParent}) {

    const [sections, setSections] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [formName, setFormName] = useState(""); // État pour le nom du formulaire
    const navigate = useNavigate();
    const { id: urlId } = useParams();
    const fetchWithToken = useFetchWithToken();
    const {t} = useTexts()
    const [formId, setFormId] = useState(null);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false); // Track unsaved changes

    const [currentSectionIndex, setCurrentSectionIndex] = useState(0);  // Initialisé à 0 pour commencer à la première section
    const handleNextSection = () => {
        if (currentSectionIndex < sections.length - 1) {
            setCurrentSectionIndex(currentSectionIndex + 1);
        }
    };

    const onDragEnd = (result) => {
        const {source, destination} = result;

        // Ne rien faire si l'élément est déplacé en dehors d'une zone droppable ou à la même place
        if (!destination || (source.droppableId === destination.droppableId && source.index === destination.index)) {
            return;
        }

        const newSections = Array.from(sections);
        const [removed] = newSections.splice(source.index, 1);
        newSections.splice(destination.index, 0, removed);
        setHasUnsavedChanges(true); // Mark as having unsaved changes
        if (setHasUnsavedChangesParent){
            setHasUnsavedChangesParent(true)
        }
        setSections(newSections);
    };

    const onDragEnd2 = (result) => {
        const {source, destination} = result;
        if (!destination) return;
        if (source.droppableId === destination.droppableId) {
            // Gérer le déplacement à l'intérieur de la même section
            const section = sections.find(s => `fields-${s.id}` === source.droppableId);
            const updatedFields = Array.from(section.fields);
            const [reorderedItem] = updatedFields.splice(source.index, 1);
            updatedFields.splice(destination.index, 0, reorderedItem);
            const updatedSections = sections.map(s => {
                if (s.id === section.id) {
                    return {...s, fields: updatedFields};
                }
                return s;
            });
            setHasUnsavedChanges(true); // Mark as having unsaved changes
            if (setHasUnsavedChangesParent){
                setHasUnsavedChangesParent(true)
            }
            setSections(updatedSections);
        } else {
            // Gérer le déplacement entre sections
            // Implémentez une logique similaire pour ajuster les sections
        }
    };


    const handlePreviousSection = () => {
        if (currentSectionIndex > 0) {
            setCurrentSectionIndex(currentSectionIndex - 1);
        }
    };

    const handleBack = () => {
        navigate('/template'); // Remplacez par le chemin correct
    };
    const removeField = (sectionIndex, fieldIndex) => {
        const updatedSections = [...sections];
        updatedSections[sectionIndex].fields.splice(fieldIndex, 1);
        setSections(updatedSections);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        // Utilisez fetch ou Axios pour envoyer les données
        try {
            let apiUrl = "";
            let method = "";
            if (!formId) {
                apiUrl = `${process.env.REACT_APP_API_URL}/api/form/add`;
                method = "POST";
            } else {
                apiUrl = `${process.env.REACT_APP_API_URL}/api/form/${formId}`;
                method = "PUT";
            }
            const body = {
                name: formName, // Incluez le nom du formulaire
                sections: sections, // Les sections du formulaire
            }
            const result = await fetchWithToken(apiUrl, method, body);
            setHasUnsavedChanges(false); // Mark as having unsaved changes
            if (setHasUnsavedChangesParent){
                setHasUnsavedChangesParent(false)
            }
            if (!isModal) {
                if (formId) {
                    navigate(`/creer-template/${formId}`); // Remplacez par le chemin correct
                } else {
                    navigate(`/creer-template/${result.id}`);
                }
            }else{

            }
        } catch (error) {
            console.error('Erreur lors de la soumission du formulaire:', error);
            // Gérez l'erreur (par exemple, affichez un message d'erreur)
        }
    };

    const removeSection = (sectionIndex) => {
        setHasUnsavedChanges(true); // Mark as having unsaved changes
        if (setHasUnsavedChangesParent){
            setHasUnsavedChangesParent(true)
        }
        const updatedSections = sections.filter((_, index) => index !== sectionIndex);
        setSections(updatedSections);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setCurrentSectionIndex(null);
    };

    const handleOpenDialog = (sectionIndex) => {
        setCurrentSectionIndex(sectionIndex);
        setOpenDialog(true);
    };
    const addSection = () => {
        setHasUnsavedChanges(true); // Mark as having unsaved changes
        if (setHasUnsavedChangesParent){
            setHasUnsavedChangesParent(true)
        }
        const newSection = {title: '', fields: [], id: Date.now(), "rules":[]};
        setSections([...sections, newSection]);

    };

    const addField = (sectionIndex, fieldType) => {
        let newField = {
            label: '',
            name: '',
            required: false,
            id: Date.now(),
            type: fieldType,
            options: fieldType === 'select' || fieldType === 'checkbox' || fieldType === 'radio' ? [] : undefined
        };
        const updatedSections = [...sections];
        updatedSections[sectionIndex].fields.push(newField);
        setSections(updatedSections);
    };

    const updateSection = (index, key, value) => {
        setHasUnsavedChanges(true); // Mark as having unsaved changes
        if (setHasUnsavedChangesParent){
            setHasUnsavedChangesParent(true)
        }
        const updatedSections = [...sections];
        updatedSections[index][key] = value;
        setSections(updatedSections);
    };
    const loadWishesDetail = async () => {
        try {


            const apiUrl = `${process.env.REACT_APP_API_URL}/api/form/template/${formId}`;
            const data = await fetchWithToken(apiUrl);
            var newForm = [];

            let key;
            for (key in data.data) {
                const section = data.data[key]

                newForm.push(section)
            }
            setSections(newForm);
            setFormName(data.name)
        } catch (error) {
            console.error('Erreur lors du chargement des vœux:', error);
        }
    };
    const updateField = (sectionIndex, fieldIndex, key, value) => {
        const updatedSections = [...sections];
        updatedSections[sectionIndex].fields[fieldIndex][key] = value;
        setSections(updatedSections);
    };
    const fieldTypes = ['text', 'textarea', 'select', 'checkbox', 'competences', 'radio'];

    const fieldName = {
        'text': t("FORM_TEXT_TYPE"),
        'textarea': t("FORM_TEXTAREA_TYPE"),
        'select': t("FORM_SELECT_TYPE"),
        'checkbox': t("FORM_CHECKBOXE_TYPE"),
        'competences': t("FORM_COMPETENCES_TYPE"),
        'radio': t("FORM_RADIO_TYPE"),

    };
    const currentSection = sections[currentSectionIndex];
    const [previewOpen, setPreviewOpen] = useState(false);

    const handlePreview = () => {
        setPreviewOpen(true);
    };

    const handleClosePreview = () => {
        setPreviewOpen(false);
    };

    useEffect(() => {
        // Si initialData contient un id, utilisez-le, sinon utilisez l'id de l'URL
        if (initialData) {
            setFormId(initialData);
        } else {
            setFormId(urlId);
        }
    }, [urlId, initialData]);

    useEffect(() => {
        if (formId) {

            loadWishesDetail();
        }
    }, [formId]); // Le tableau vide assure que l'effet s'exécute une seule fois au montage

    const addFieldTypeDialog = (
        <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle>Add New Field</DialogTitle>
            <DialogContent>
                <FormControl fullWidth>
                    <InputLabel>Type</InputLabel>
                    <Select
                        value=""
                        onChange={(e) => {
                            addField(currentSectionIndex, e.target.value);
                            handleCloseDialog();
                        }}
                    >
                        {fieldTypes.map(type => (
                            <MenuItem key={type} value={type}>{fieldName[type]}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                    Annuler
                </Button>
            </DialogActions>
        </Dialog>
    );

    const previewDialog = (

        <Dialog open={previewOpen} onClose={handleClosePreview}>
            <DialogTitle>Aperçu</DialogTitle>
            <DialogContent>
                {sections.map((section, sectionIndex) => (
                    <Grid item xs={12} key={section.title}>
                        <Typography variant="h6">{section.title}</Typography>
                        <Grid container spacing={2}>
                            {section.fields.map(field => (
                                <Grid item xs={12} sm={12} key={field.name}>
                                    <FormFieldView
                                        field={field}
                                        sectionIndex={sectionIndex}

                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClosePreview} color="primary">Close</Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <Box sx={{width: '100%'}}>
            <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>

                        {!isModal && (
                            <TextField
                                label={t("FIELD_FORM_NAME")}
                                variant="outlined"
                                value={formName}
                                onChange={(e) => setFormName(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                        )}


                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={addSection} variant="contained"
                                startIcon={<AddIcon/>}>{t("FORM_ADD_SECTION")}</Button>
                        <Button onClick={handlePreview} variant="contained" startIcon={<PreviewIcon/>}>Aperçu</Button>
                    </Grid>

                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppableSections">
                            {(provided) => (
                                <Grid item xs={12} {...provided.droppableProps} ref={provided.innerRef}>
                                    {sections.map((section, sectionIndex) => (
                                        <Draggable key={section.id} draggableId={`section-${section.id}`}
                                                   index={sectionIndex}>
                                            {(provided) => (
                                                <Grid item xs={12}
                                                      ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                    <Accordion>
                                                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                                            <Typography>{section.title || t("EMPTY_SECTION_NAME")}</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <TextField
                                                                label={t("FIELD_SECTION_NAME")}
                                                                variant="outlined"
                                                                value={section.title}
                                                                onChange={(e) => updateSection(sectionIndex, 'title', e.target.value)}
                                                                fullWidth
                                                                margin="normal"
                                                            />
                                                            <FormControl fullWidth margin="normal">
                                                                <InputLabel id="restriction-label">Restriction</InputLabel>
                                                                <Select
                                                                    labelId="restriction-label"
                                                                    multiple
                                                                    onChange={(e) => updateSection(sectionIndex, 'rules', e.target.value)}
                                                                    value={section.rules ? section.rules: [] }
                                                                    input={<OutlinedInput label="Restriction" />}
                                                                    renderValue={(selected) => (
                                                                        <div>
                                                                            {selected.map((value) => (
                                                                                <Chip key={value} label={positions.find(pos => pos.code === value).label} />
                                                                            ))}
                                                                        </div>
                                                                    )}
                                                                >
                                                                    {positions.map((position) => (
                                                                        <MenuItem key={position.code} value={position.code}>
                                                                            {position.label}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>

                                                            <DragDropContext onDragEnd={onDragEnd2}>
                                                                <Droppable droppableId={`fields-${section.id}`}
                                                                           type="field">
                                                                    {(provided) => (
                                                                        <div
                                                                            ref={provided.innerRef} {...provided.droppableProps}>
                                                                            {section.fields.map((field, fieldIndex) => (
                                                                                <Draggable key={field.id}
                                                                                           draggableId={`field-${field.id}`}
                                                                                           index={fieldIndex}>
                                                                                    {(provided) => (
                                                                                        <div
                                                                                            ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                            <AdminFormField
                                                                                                field={field}
                                                                                                fieldIndex={fieldIndex}
                                                                                                sectionIndex={sectionIndex}
                                                                                                handleUpdateField={updateField}
                                                                                                handleRemoveField={removeField}
                                                                                                fieldName={fieldName}
                                                                                                fieldTypes={fieldTypes}
                                                                                            />
                                                                                        </div>
                                                                                    )}
                                                                                </Draggable>
                                                                            ))}
                                                                            {provided.placeholder}
                                                                        </div>
                                                                    )}
                                                                </Droppable>
                                                            </DragDropContext>
                                                            <Button onClick={() => handleOpenDialog(sectionIndex)}
                                                                    color="primary"
                                                                    startIcon={
                                                                        <AddIcon/>}>{t("FORM_ADD_FIELD")}</Button>


                                                            <Button size="small" color="error"
                                                                    startIcon={<CloseIcon/>}
                                                                    onClick={() => removeSection(sectionIndex)}>
                                                                {t("FORM_DELETE_SECTION")}
                                                            </Button>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </Grid>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </Grid>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Grid>
                {addFieldTypeDialog}
                <Grid item xs={12}>
                    {(hasUnsavedChanges || !formId) && (

                    <Button type="submit" color="primary">{formId ? t("SAVE_MY_MODIF") : t("LABEL_ADD_QUESTIONAIRE")}</Button>
                    )}
                    {!isModal && (
                            <Button variant="contained" onClick={handleBack}>Retour aux modèles</Button>
                        )}
                    {(isModal && closeModal ) && (
                        <Button variant="contained" onClick={closeModal}>Fermer</Button>
                    )}
                </Grid>
            </form>
            {previewDialog}
        </Box>
    );
}

export default FormBuilder;
