import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
    Container,
    Typography,
    TextField,
    Button,
    Divider,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    InputAdornment,
    Dialog,
    DialogTitle,
    DialogContent,
    Switch
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { useFetchWithToken } from '../hooks/useFetchWithToken';
import withAdminRole from "../hoc/withAdminRole";
import './styles/TextEditor.css';

const TextEditor = () => {
    const [texts, setTexts] = useState([]);
    const [newKey, setNewKey] = useState('');
    const [newText, setNewText] = useState('');
    const [editText, setEditText] = useState({ id: '', key: '', text: '' });
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const fetchWithToken = useFetchWithToken();

    // Table states
    const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');
    const [searchTable, setSearchTable] = useState('');
    const [isTableCompact, setIsTableCompact] = useState(false);
    const [dialogType, setDialogType] = useState('');

    const fetchTexts = useCallback(async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/texts`;
            const data = await fetchWithToken(apiUrl, "GET");
            setTexts(data);
        } catch (error) {
            console.error(error);
        }
    }, [fetchWithToken, setTexts]);

    useEffect(() => {
        fetchTexts();
    }, [fetchTexts]);

    const addText = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/texts`;
            const newTextData = { key: newKey, text: newText };
            await fetchWithToken(apiUrl, "POST", newTextData);
            setNewKey('');
            setNewText('');
            fetchTexts();
        } catch (error) {
            console.error(error);
        }
    };

    const handleTableSearch = (e) => {
        setSearchTable(e.target.value);
    };

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchQuery(searchTable);
        }, 300); // delay for perf
        return () => {
            clearTimeout(handler);
        };
    }, [searchTable]);

    const filteredTexts = useMemo(() => {
        if (!debouncedSearchQuery) {
            return texts;
        }
        return texts.filter(text =>
            text.key && debouncedSearchQuery &&
            text.key.toLowerCase().includes(debouncedSearchQuery.toLowerCase())
        );
    }, [debouncedSearchQuery, texts]);

    const handleDialogOpen = (text, type) => {
        if (type === 'edit') {
            setEditText(text);
            setDialogType('edit');
        } else if (type === 'delete') {
            setEditText(text);
            setDialogType('delete');
        }
        setIsDialogOpen(true);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    const handleEditSave = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/texts/${editText.id}`;
            await fetchWithToken(apiUrl, "PUT", editText);
            fetchTexts();
            setIsDialogOpen(false);
        } catch (error) {
            console.error(error);
        }
        setIsDialogOpen(false);
    };

    const handleDeleteText = () => {
        console.log('Delete text:', editText);
        setIsDialogOpen(false);
        fetchTexts();
    }

    return (
        <Container>
            <Typography variant="h4" gutterBottom style={{ textAlign: 'center', color: 'black', margin: '16px 0' }}>
                Configurer les textes d'Azimuth
            </Typography>
            <div className='add-text-container'>
                <div className='toggle-compact-view'>
                    <p>Vue Compacte</p>
                    <Switch
                        checked={isTableCompact}
                        onChange={() => setIsTableCompact(!isTableCompact)}
                        color='primary'
                    />
                </div>
                <div className='search-table'>
                    <TextField
                        id='search-table'
                        label='Rechercher'
                        value={searchTable}
                        onChange={handleTableSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <TextField id='text-input' label="KEY_LABEL" value={newKey} onChange={e => setNewKey(e.target.value)} />
                <TextField id='text-input' label="Texte" value={newText} onChange={e => setNewText(e.target.value)} />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={addText}
                    id='add-text-button'
                >
                    Ajouter le texte
                </Button>
            </div>
            <Divider />
            <TableContainer sx={{ maxHeight: '100%' }}>
                <Table stickyHeader size={isTableCompact ? 'small' : 'medium'}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>KEY_LABEL</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Texte</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredTexts.map(text => (
                            <TableRow key={text.id}>
                                <TableCell>{text.key}</TableCell>
                                <TableCell align='left'>{text.text}</TableCell>
                                <TableCell >
                                    <div className='actions-button'>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleDialogOpen(text, 'edit')}
                                        >
                                            Modifier
                                        </Button>
                                        {/* <Button
                                            variant='contained'
                                            color='secondary'
                                            onClick={() => handleDialogOpen(text, 'delete')}
                                        >
                                            Supprimer
                                        </Button> */}
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={isDialogOpen} onClose={handleDialogClose}>
                {dialogType === 'delete' ? (
                    <>
                        <DialogTitle>Supprimer le texte</DialogTitle>
                        <DialogContent>
                            <Typography>
                                Êtes-vous sûr de vouloir supprimer ce texte ?
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleDialogClose}
                            >
                                Annuler
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleDeleteText}
                            >
                                Supprimer
                            </Button>
                        </DialogContent>
                    </>
                ) : (
                    <>
                        <DialogTitle>Modifiez le label et le texte</DialogTitle>
                        <DialogContent>
                            <TextField
                                margin="dense"
                                label="KEY_LABEL"
                                fullWidth
                                value={editText.key}
                                onChange={e => setEditText({ ...editText, key: e.target.value })}
                            />
                            <TextField
                                margin="dense"
                                label="Texte"
                                fullWidth
                                value={editText.text}
                                onChange={e => setEditText({ ...editText, text: e.target.value })}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleEditSave}
                            >
                                Sauvegarder
                            </Button>
                        </DialogContent>
                    </>
                )}
            </Dialog>
        </Container>
    );
};

export default withAdminRole(TextEditor);