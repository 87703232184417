import React, {useState} from 'react';
import {Link, useNavigate} from "react-router-dom";


function NavbarBlock({item}) {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const handleNavigation = () => {
        setIsOpen(false);
        navigate(item.to);
    };

    return (
        <div className={`dropdown dropdownMenu ${isOpen ? 'open' : ''}`} onClick={handleNavigation}
             style={{cursor: 'pointer'}}
             onMouseEnter={() => setIsOpen(true)}
             onMouseLeave={() => setIsOpen(false)}>
            <a data-toggle="dropdown" data-submenu="">{item.text}</a>
        </div>
    );
}

export default NavbarBlock;
