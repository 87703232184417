import React, { useState, useCallback } from 'react';
import {
    TextField,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    Checkbox,
    FormControlLabel,
    Divider,
    Card,
    CardContent,
    Box,
    IconButton,
    Grid,
} from '@mui/material';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import DeleteIcon from "@mui/icons-material/Delete";
import FieldOptionsEditor from "./FieldOptionsEditor";
import useTexts from "../../hooks/useTexte";

function AdminFormField({
                            field,
                            fieldIndex,
                            sectionIndex,
                            fieldTypes,
                            handleUpdateField,
                            handleRemoveField,
                            fieldName,
                        }) {
    const [displayForm, setDisplayForm] = useState(true);
    const [localField, setLocalField] = useState(field);
    const { t } = useTexts();

    const handleChange = useCallback((key, value) => {
        setLocalField(prevField => ({ ...prevField, [key]: value }));
        handleUpdateField(sectionIndex, fieldIndex, key, value);
    }, [sectionIndex, fieldIndex, handleUpdateField]);

    const toggleShowDetail = () => {
        setDisplayForm(!displayForm);
    };

    return (
        <Card variant="outlined" sx={{ mb: 4, borderRadius: 2, boxShadow: 3 }}>
            <CardContent>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Field Label"
                                variant="outlined"
                                value={localField.label}
                                onChange={(e) => handleChange('label', e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            variant="outlined"
                                            checked={field.required}
                                            onChange={(e) => handleChange('required', e.target.checked)}
                                        />
                                    }
                                    label={t("TEXT_FIELD_MANDATORY")}
                                    sx={{ mt: 2 }}
                                />
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Type</InputLabel>
                                <Select
                                    value={field.type}
                                    onChange={(e) => handleChange('type', e.target.value)}
                                >
                                    {fieldTypes.map((type, index) => (
                                        <MenuItem key={index} value={type}>{fieldName[type]}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {displayForm && (
                                <TransitionGroup>
                                    <CSSTransition key="options" timeout={300} classNames="fade">
                                        <Box>
                                            {(field.type === 'select' || field.type === 'checkbox' || field.type === 'radio') && (
                                                <FieldOptionsEditor
                                                    options={field.options || []}
                                                    label={t("FORM_ADD_OPTION")}
                                                    onChange={(updatedOptions) => handleUpdateField(sectionIndex, fieldIndex, 'options', [...updatedOptions])}
                                                />
                                            )}
                                            {localField.type === 'competences' && (
                                                <>
                                                    <FieldOptionsEditor
                                                        label={t("FORM_ADD_COMPTENCES")}
                                                        options={field.competences || []}
                                                        onChange={(updatedOptions) => handleUpdateField(sectionIndex, fieldIndex, 'competences', updatedOptions)}
                                                    />
                                                    <FieldOptionsEditor
                                                        options={field.options || []}
                                                        label={t("FORM_ADD_COMPTENCES_LEVEL")}
                                                        onChange={(updatedOptions) => handleUpdateField(sectionIndex, fieldIndex, 'options', [...updatedOptions])}
                                                    />
                                                </>
                                            )}
                                        </Box>
                                    </CSSTransition>
                                </TransitionGroup>
                            )}
                        </Grid>
                    </Grid>
                </Box>
                <IconButton onClick={() => handleRemoveField(sectionIndex, fieldIndex)} color="error" sx={{ mt: 2 }}>
                    <DeleteIcon />
                </IconButton>
            </CardContent>
            <Divider />
        </Card>
    );
}

export default AdminFormField;
