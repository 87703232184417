const StatusTypes = {
    INSCRIPTION: "Inscription",
    VALIDEE: "Validée",
    NON_VALIDE: "Non valide",
    DEMANDE_DEROGATION_SOUMISE: "Demande de dérogation soumise",
    DEROGATION_ACCORDEE: "Dérogation accordée",
    A_CONFIRMER: "À confirmer",
    CONFIRME: "Confirmé",
    ANNULE: "Annulé"
};
const statusOptions = Object.entries(StatusTypes).map(([key, label]) => ({
    key,
    label
}));

const StatusText = {
    INSCRIPTION: "Voeu en attente de validation",
    VALIDEE: "Votre voeu est valide , en attente de creation d'un CEP",
    NON_VALIDE: "Vous n'est pas iligible à cette formation, une demande de dérogation doit étre faite",
    DEMANDE_DEROGATION_SOUMISE: "Demande de dérogation soumise",
    DEROGATION_ACCORDEE: "Dérogation accordée",
    A_CONFIRMER: "À confirmer",
    CONFIRME: "Confirmé",
    ANNULE: "Annulé"
};
const VadidAction = {
    [StatusTypes.INSCRIPTION]: ["remove"],
    [StatusTypes.NON_VALIDE]: ["remove"],
    [StatusTypes.DEMANDE_DEROGATION_SOUMISE]: ["remove"],
    [StatusTypes.VALIDEE]: ["remove"],
    [StatusTypes.PRIS]: ["remove", "validate"],
    [StatusTypes.A_CONFIRMER]: []
};

function formatStatus(status, inAuther, inautherUse) {

    switch (status) {
        case "":
        case "WAIT":
            if (inautherUse && inAuther == "Yes"){
                return "En attente de validation sur une autre formation"
            }else {
                return "En attente"
            }
        case "REFUSE":
            return "Refusé"
        case "WAIT_VALIDATION":
            return "En attente de confirmation";
        case "PROPOSITION":
            return "Proposition par la moulinette";
        case "VALIDATE":
            return "inscript";
    }
}

const statuses = [
    {value: 'WAIT', label: 'Liste d\'attente'},
    {value: 'WAIT_VALIDATION', label: 'En attente de validation'},
    {value: 'PROPOSITION', label: 'Proposition par la moulinette'},
    {value: 'VALIDATE', label: 'Inscrit'},
    {value: 'REFUSE', label: 'Refusé'},

];

module.exports = {
    StatusTypes,
    statuses,
    StatusText,
    VadidAction,
    statusOptions, formatStatus
};