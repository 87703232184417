import React, {useState} from 'react';
import {
    Button,
    Grid,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,

    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    TextField,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Card,
    CardContent,
    CardMedia,
    FormControlLabel,
    FormGroup, Checkbox,
} from '@mui/material';
import {formatDate} from '../../Utils/functionTransforms';

import FormationForm from "./FormationForm";
import {branchOptions} from '../../Utils/BranchOption';
import {LevelOptions} from '../../Utils/LevelOption';
import {useFetchWithToken} from "../../hooks/useFetchWithToken"

import useTexts from "../../hooks/useTexte";

import {useNavigate} from "react-router-dom";
import CircularIcon from "@mui/icons-material/Description";
import ListIcon from "@mui/icons-material/List";
import StagiareCardAdmin from "./StagiareCardAdmin";
import {statuses} from "../../Utils/Status";
import SearchModal from "../Search/SearchModal";
import {useConfirm} from "../../Provider/ConfirmProvider";
import {useAuth} from "../../Context/AuthContext";
import useRoles from "../../hooks/useRoles";
import MoulinetteModal from "./MoulinetteModal";

const FormationAdminItem = ({wish, index, functionOptions, addFormation, questionnaires, loadWishes}) => {
    const fetchWithToken = useFetchWithToken();
    const [filters, setFilters] = React.useState({
        status: {
            'WAIT': false,
            'WAIT_VALIDATION': false,
            'VALIDATE': false,

        },
        'hideInWait': 1,
        codeSycomore: '',
    });
    const {currentFunction, token} = useAuth();

    const [searchOpen, setSearchOpen] = useState(false);
    const {requestConfirm} = useConfirm();
    const chefFunction = functionOptions.find(func => func.id == currentFunction);
    const roles = useRoles(chefFunction ? chefFunction.fonction : "");
    const isAdministrator = roles.includes('Administrator');
    const [openModalList, setOpenModalList] = useState(false);
    const [openModalListWait, setOpenModalListWait] = useState(false);

    const [editMode, setEditMode] = useState(false);
    const [updatedWish, setUpdatedWish] = useState({...wish});
    const [users, setUsers] = useState([]);
    const [usersWait, setUsersWait] = useState([]);

    const [openMoulinetteDialog, setOpenMoulinetteDialog] = useState(false);

    const {t} = useTexts()
    const [openResponsableModal, setOpenResponsableModal] = useState(false);
    const [openQuestionnaireModal, setOpenQuestionnaireModal] = useState(false);
    const [selectedResponsable, setSelectedResponsable] = useState('');
    const [selectedQuestionnaire, setSelectedQuestionnaire] = useState('');
    const [selectedFormationId, setSelectedFormationId] = useState(null);
    const navigate = useNavigate();
    const userCanSeeSection = (sectionPermissions) => {
        if (!token) {
            return sectionPermissions.includes('guest');
        }
        return sectionPermissions.some(permission => roles.includes(permission));
    };

    const handleOpenModalEdit = async () => {


        const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${wish.id}`;
        const data = await fetchWithToken(apiUrl);
        setUpdatedWish(data);
        setEditMode(true)

    };
    const handleOpenResponsableModal = (id) => {
        setSelectedFormationId(id);
        setOpenResponsableModal(true);
    };

    const handleCloseResponsableModal = () => {
        setOpenResponsableModal(false);
        setSelectedFormationId(null);
    };
    const exportWaitList = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/fomrationsadmin/waitlist/${wish.id}/export`;

            // Exemple de données à envoyer au backend (formData)
            const formData = {};

            // Effectuer la requête et récupérer la réponse complète (pas seulement le blob)
            const response = await fetchWithToken(apiUrl, "POST", formData, false, "RESPONSE");

            // Vérifier si la réponse est correcte
            if (!response.ok) {
                throw new Error('Erreur lors de la récupération du fichier.');
            }

            // Récupérer le blob depuis la réponse
            const blob = await response.blob();

            // Récupérer l'en-tête Content-Disposition
            const contentDisposition = response.headers.get('Content-Disposition');
            console.log(response.headers)
            // Extraire le nom de fichier depuis l'en-tête (si présent)
            let fileName = 'export.xlsx'; // Nom par défaut
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);


                if (fileNameMatch.length > 1) {
                    fileName = fileNameMatch[1];
                }
            }
            // Créer un lien de téléchargement et déclencher le téléchargement du fichier
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();

            // Nettoyage après téléchargement
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Erreur lors de l\'export en PDF :', error);
        }
    };


    const handleOpenQuestionnaireModal = (id) => {
        setSelectedFormationId(id);
        setOpenQuestionnaireModal(true);
    };

    const handleCloseQuestionnaireModal = () => {
        setOpenQuestionnaireModal(false);
        setSelectedFormationId(null);
    };

    const handleChangeResponsable = (event) => {
        setSelectedResponsable(event.target.value);
    };

    const handleChangeQuestionnaire = (event) => {
        setSelectedQuestionnaire(event.target.value);
    };
    const handleSearchToggle = () => {
        setSearchOpen(!searchOpen);
    };
    const handleOpenFormation = (wish) => {

        //
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${wish.id}/open`;

        const data = fetchWithToken(apiUrl).then(data => {
                setUpdatedWish(prevWish => ({
                    ...prevWish,
                    status: "open"
                }));


            }
        );
    };
    const handleCloseMoulinetteFormation = (wish) => {

        //
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${wish.id}/closemoulinette`;

        const data = fetchWithToken(apiUrl).then(data => {
                setUpdatedWish(prevWish => ({
                    ...prevWish,
                    status: "selected"
                }));


            }
        );
    };

    const handleSelectQuestionnaire = async (formationId, questionnaireId) => {
        // Logique pour associer le questionnaire sélectionné à la formation
        // Exemple d'appel API:
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${formationId}/assign-questionnaire`;
        await fetchWithToken(apiUrl, 'POST', {questionnaireId});
        handleCloseQuestionnaireModal();
    };
    const handleListUser = async (id) => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/fomrationsadmin/user/${wish.id}`;
            const data = await fetchWithToken(apiUrl);

            setUsers(data);
            setOpenModalList(true)
        } catch (error) {
        } finally {
        }

    };
    const handleListUserWait = async (id) => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/fomrationsadmin/waitlist/${wish.id}`;
            const data = await fetchWithToken(apiUrl);

            setUsersWait(data);
            setOpenModalListWait(true)
        } catch (error) {
        } finally {
        }

    };


    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic
    };

    const handleAddTrainee = (newuser, function_id) => {
        // Handle add trainee logic
        requestConfirm(t("CONFIRMATION_ADD_STAGIAIRE"), async () => {
            try {
                const apiUrl = `${process.env.REACT_APP_API_URL}/api/fomrationsadmin/user/${wish.id}/${newuser.id}/${function_id}/force`;
                await fetchWithToken(apiUrl);
                handleListUser();
                setSearchOpen(false)
            } catch (error) {
                console.error('Erreur lors de la récupération des tâches:', error);
                return [];
            }
        });
    };

    const handleLaunchMoulinetteValidation = async () => {
        try {
            var data = {
                type: "validerliste"
            }
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${wish.id}/moulinette`;
            await fetchWithToken(apiUrl, "POST", data);

            handleListUser();
        } catch (error) {
        } finally {
        }
    };

    const handleLaunchMoulinette = async (place, type, specialities, functionCodes) => {
        try {
            if ((place && type)) {
                var data = {
                    place,
                    type,
                    specialities,
                    functionCodes

                }
                const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${wish.id}/moulinette`;
                const {jobId} = await fetchWithToken(apiUrl, "POST", data);
            //    setOpenMoulinetteDialog(false)
            }

        } catch (error) {
        } finally {
        }
    };

    const handleChange = (e) => {
        const {name, checked} = e.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            status: {
                ...prevFilters.status,
                [name]: checked,
            },
        }));
    };


    const handleChangeHide = (e) => {
        const {name, checked} = e.target;
        setFilters({
            ...filters,
            hideInWait: checked,
        });
    };
    const handleCodeSycomoreChange = (e) => {
        setFilters({
            ...filters,
            codeSycomore: e.target.value,
        });
    };
    const isVisible = ['open', 'selecting'].includes(updatedWish.status);
    const isSelecting = ['selected', 'selecting'].includes(updatedWish.status);
    const hasQuestionaitre = updatedWish.questionaire_id ? true : false;
    const level = LevelOptions.find((branch) => wish.level === branch.key)
    const branch = branchOptions.find((branch) => wish.branch === branch.key)
    const applyFilters = (user) => {
        const userStatus = user.status || 'WAIT';

        // Check if user status matches any of the selected statuses
        const isStatusSelected = Object.values(filters.status).some(value => value);
        const statusMatch = isStatusSelected ? Object.keys(filters.status).some(status => filters.status[status] && status === userStatus) : true;
        // Check if code Sycomore matches
        const codeSycomoreMatch = filters.codeSycomore ? user.user_code_sycomore.includes(filters.codeSycomore) : true;
        const hideMtach = filters.hideInWait ? user.other_inscription_wait_validation == "No" : true;

        return statusMatch && codeSycomoreMatch && hideMtach;
    };
    return (
        <>

            <Card sx={{
                maxWidth: 345,
                margin: 2,
                textAlign: 'center',
                border: '2px solid #ccc',
                borderRadius: '10px',
                overflow: 'visible'
            }}>
                <CardMedia
                    component="img"
                    sx={{width: '80px', height: '80px', margin: '-25px auto 10px', zIndex: 1, position: 'relative'}}
                    image={branch.logo}

                />
                <CardContent>
                    <Typography sx={{fontFamily: 'Ubuntu', fontWeight: "bold", fontSize: "30px"}} gutterBottom
                                variant="h5" component="div">{level.label} {branch.label}</Typography>
                    <Typography sx={{fontFamily: 'Ubuntu', fontWeight: "bold", fontSize: "24px"}} variant="h6"
                                component="div">{wish.nom}</Typography>
                    <Typography sx={{fontFamily: 'Ubuntu'}} variant="body1" color="text.secondary">

                        <div>du {formatDate(wish.date)}</div>
                        <div>au {formatDate(wish.end_date)}</div>
                        <div>{t("NBR_VOEUX_IN_THIS_FORM", wish.user_count)}</div>
                    </Typography>

                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px 0'}}>
                        {/*{statusIcons[formation.status].icon}<Typography variant="body1" sx={{ marginLeft: 1 }}>{statusIcons[formation.status].label}</Typography>*/}
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px', textAlign: "left"}}>
                        {isAdministrator && (
                            <Button variant="contained" startIcon={<CircularIcon/>} sx={{width: '100%'}}
                                    onClick={handleOpenModalEdit}>Modifier</Button>
                        )}
                        <Button variant="contained" onClick={() => handleListUser()} startIcon={<ListIcon/>}
                                sx={{width: '100%'}}>LISTE DES STAGIAIRES</Button>
                        <Button variant="contained" onClick={() => handleListUserWait()} startIcon={<ListIcon/>}
                                sx={{width: '100%'}}>LIste d'attentes</Button>
                        {isAdministrator && (

                            <Button variant="contained" onClick={() => handleOpenQuestionnaireModal(wish.id)}
                                    startIcon={<ListIcon/>}
                                    sx={{width: '100%'}}>Choisir un questionnaire</Button>
                        )}

                        {isAdministrator && wish.status === "draft" && (
                            <Button variant="contained" onClick={() => handleOpenFormation(wish)}
                                    startIcon={<ListIcon/>}
                                    sx={{width: '100%'}}>{t("BUTTON_OPEN_FORMATION")}</Button>
                        )}
                        {isAdministrator && wish.status === "selecting" && (
                            <Button variant="contained" onClick={() => handleCloseMoulinetteFormation(wish)}
                                    startIcon={<ListIcon/>}
                                    sx={{width: '100%'}}>{t("BUTTON_CLOSE_FORMATION_MOULINETTE")}</Button>
                        )}
                        {isAdministrator && (
                            <Button variant="contained" onClick={() => setOpenMoulinetteDialog(true)}
                                    startIcon={<IconButton/>} sx={{width: '100%'}}>
                                {t("BUTTON_START_MOULINETTE")}
                            </Button>
                        )}

                    </Box>
                </CardContent>

            </Card>


            <FormationForm
                open={editMode}
                onClose={() => setEditMode(false)}
                onAdd={addFormation}
                initialData={updatedWish}
                functionOptions={functionOptions}
                LevelOptions={LevelOptions}
                questionnaires={questionnaires}
                branchOptions={branchOptions}
            />
            <Dialog open={openQuestionnaireModal} onClose={handleCloseQuestionnaireModal}>
                <DialogTitle>{t("TITLE_SELECT_QUESTIONNAIRE")}</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <InputLabel>{t("LABEL_QUESTIONNAIRE")}</InputLabel>
                        <Select
                            value={selectedQuestionnaire}
                            label={t("LABEL_QUESTIONNAIRE")}
                            onChange={handleChangeQuestionnaire}
                            displayEmpty={false}
                        >
                            {questionnaires.map((questionnaire) => (
                                <MenuItem key={questionnaire.id} value={questionnaire.id}>
                                    {questionnaire.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseQuestionnaireModal} color="primary">
                        {t("BUTTON_CANCEL")}
                    </Button>
                    <Button onClick={() => handleSelectQuestionnaire(selectedFormationId, selectedQuestionnaire)}
                            color="primary">
                        {t("BUTTON_SELECT")}
                    </Button>
                </DialogActions>
            </Dialog>
           <MoulinetteModal openMoulinetteDialog={openMoulinetteDialog}
                            setOpenMoulinetteDialog={setOpenMoulinetteDialog}
           handleLaunchMoulinette={handleLaunchMoulinette}
           />

            {openModalList && (
                <Dialog open={openModalList} onClose={() => setOpenModalList(false)} maxWidth="lg" fullWidth>
                    <DialogContent>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <FormGroup>
                                        {statuses.map((option) => (
                                            <FormControlLabel
                                                key={option.value}
                                                control={
                                                    <Checkbox
                                                        checked={filters.status[option.value]}
                                                        onChange={handleChange}
                                                        name={option.value}
                                                    />
                                                }
                                                label={option.label}
                                            />
                                        ))}
                                    </FormGroup>
                                    <FormGroup>
                                        <FormControlLabel
                                            key="hideInWait"
                                            control={
                                                <Checkbox
                                                    checked={filters.hideInWait}
                                                    onChange={handleChangeHide}
                                                    name={"hideInWait"}
                                                />
                                            }
                                            label={"Cacher en attente sur une autre formation"}
                                        />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        fullWidth
                                        label="Filtre par code Sycomore"
                                        name="codeSycomore"
                                        value={filters.codeSycomore}
                                        onChange={handleCodeSycomoreChange}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Box display="flex" gap={2} mt={2}>
                                    {isAdministrator && (
                                        <>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                sx={{ height: '40px' }}  // Définir une hauteur fixe

                                                onClick={() => setSearchOpen(true)}
                                            >
                                                {t("BTN_ADD_STAGIARE")}
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                sx={{ height: '40px' }}  // Définir une hauteur fixe

                                                onClick={handleLaunchMoulinetteValidation}
                                            >
                                                {t("VALID_MOULINETTE_PROPAL")}
                                            </Button>
                                        </>
                                    )}
                                </Box>
                            </Grid>
                        </form>
                        <Grid container spacing={2} sx={{mt: 2}}>
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Code Sycomore</TableCell>
                                                <TableCell>Prénom</TableCell>
                                                <TableCell>Nom</TableCell>
                                                <TableCell>Structure</TableCell>
                                                <TableCell>fonction</TableCell>

                                                <TableCell>Statut</TableCell>
                                                <TableCell>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {users && users.filter(applyFilters).map((user, index) => (
                                                <StagiareCardAdmin key={index} user={user} index={index}
                                                                   isAdministrator={isAdministrator}
                                                                   refreshUser={handleListUser} formation={wish}/>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            )}
            {openModalListWait && (
                <Dialog open={openModalListWait} onClose={() => setOpenModalListWait(false)} maxWidth="lg" fullWidth>
                    <DialogContent>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>

                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        fullWidth
                                        label="Filtre par code Sycomore"
                                        name="codeSycomore"
                                        value={filters.codeSycomore}
                                        onChange={handleCodeSycomoreChange}
                                        variant="outlined"
                                    />
                                </Grid>

                                {isAdministrator && (
                                    <button onClick={(e) => exportWaitList()}>Exporter</button>

                                )}

                            </Grid>
                        </form>
                        <Grid container spacing={2} sx={{mt: 2}}>
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Code Sycomore</TableCell>
                                                <TableCell>Prénom</TableCell>
                                                <TableCell>Nom</TableCell>
                                                <TableCell>Structure</TableCell>
                                                <TableCell>fonction</TableCell>

                                                <TableCell>Statut</TableCell>
                                                <TableCell>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {usersWait && usersWait.filter(applyFilters).map((user, index) => (
                                                <StagiareCardAdmin key={index} user={user} index={index}
                                                                   isAdministrator={isAdministrator}
                                                                   refreshUser={handleListUser} formation={wish}
                                                                   waitList={1}/>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            )}
            <Dialog open={searchOpen} onClose={() => setSearchOpen(false)} aria-labelledby="search-dialog-title">


                <DialogTitle id="search-dialog-title">{t("SEARCH")}</DialogTitle>
                <DialogContent>
                    <SearchModal onClick={handleAddTrainee} functionMode={1}/>
                </DialogContent>
            </Dialog>

        </>

    );
};

export default FormationAdminItem;
