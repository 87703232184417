import React, { useState } from 'react';
import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Card,
    CardContent,
    Typography,
} from '@mui/material';
import { calculateEndDate, formatDate } from "../../Utils/functionTransforms";
import { useFetchWithToken } from "../../hooks/useFetchWithToken";
import useTexts from "../../hooks/useTexte";
import StructureActionFormation from "./StructureActionFormation";
import { LevelOptions } from "../../Utils/LevelOption";
import { branchOptions } from "../../Utils/BranchOption";

import './styles/StructureItemChef.css';

const StructureItemListFormation = ({ chef, structure, avisChef, loadChef }) => {
    const [open, setOpen] = useState(false);
    const [openValidate, setOpenValidate] = useState(false);
    const [openErrorAvis, setOpenErrorAvis] = useState(false);
    const fetchWithToken = useFetchWithToken();
    const { t } = useTexts();
    const [selectedRegister, setSelectedRegister] = useState(null);

    const handleClickOpen = (register) => {
        setSelectedRegister(register);
        setOpen(true);
    };
    const handleClickOpenValidate = (register) => {
        setSelectedRegister(register);
        setOpenValidate(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleCloseValidate = () => {
        setOpenValidate(false);
    };

    const handleCloseErrorAvis = () => {
        setOpenErrorAvis(false);
    };
    const handleValidateFormation = (register) => {
        setSelectedRegister(register);
        if (!avisChef) {
            setOpenErrorAvis(true);
        } else {
            onValidateFormation()
        }
    };
    const onValidateFormation = async () => {
        try {
            const body = {
                formation_user_id: selectedRegister.status_id
            };
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formation/validate`;
            await fetchWithToken(apiUrl, "POST", body);
            setOpenValidate(false);
            setSelectedRegister(false)
            loadChef()
        } catch (error) {
            console.log(error);
        }
    };

    const handleConfirm = async () => {
        try {
            const body = {
                formation_user_id: selectedRegister.status_id
            };
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formation/refuse`;
            await fetchWithToken(apiUrl, "POST", body);
            handleClose();
            loadChef()
        } catch (error) {
            console.log(error);
        }

    };

    return (
        <>
            {chef.formationsRegister.map((register, index) => {
                const level = LevelOptions.find((option) => register.level === option.key);
                const branch = branchOptions.find((option) => register.section_id === option.id);

                return (
                    <Grid item xs={12} sm={12} md={12} lg={12} key={register.formation_id} >
                        <Card
                            sx={{
                                maxWidth: 245,
                                margin: 'auto',
                                textAlign: 'center',
                                border: '2px solid #ccc',
                                borderRadius: '10px',
                                overflow: 'visible',
                                position: 'relative',
                                paddingTop: '40px',
                            }}
                        >
                            <CardContent>
                                <Typography
                                    sx={{ fontFamily: 'Ubuntu', fontWeight: 'bold', fontSize: '20px' }}
                                    gutterBottom variant="h6" component="div">
                                    {level ? level.label : 'N/A'}
                                </Typography>
                                <Typography sx={{ fontFamily: 'Ubuntu' }} variant="body1"
                                    color="text.secondary">
                                    du {formatDate(register.date)} au {formatDate(register.end_date)}
                                </Typography>
                                <Typography sx={{ fontFamily: 'Ubuntu' }} variant="body1"
                                    color="text.secondary">
                                    {`${register.price} €`}
                                </Typography>
                                {structure.diff === 1 && (
                                    <StructureActionFormation
                                        register={register}
                                        handleClickOpenValidate={handleClickOpenValidate}
                                        handleClickOpen={handleClickOpen}
                                        chef={chef}
                                    />
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                );
            })}

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t("TEXT_CONFIRMARION_REFUSE_FORMATION_CHEF")}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" color="primary">Non</Button>
                    <Button onClick={handleConfirm} autoFocus color="error" variant="contained">
                        {t("BUTTON_CONFIRMARION_REFUSE_FORMATION_CHEF")}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openValidate}
                onClose={handleCloseValidate}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t("TEXT_CONFIRMARION_FORMATION_CHEF")}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleCloseValidate} variant="outlined" color="primary">Non</Button>
                    <Button onClick={e => handleValidateFormation()} autoFocus color="success" variant="contained">
                        {t("BUTTON_CONFIRMARION_VALIDATION_FORMATION_CHEF")}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openErrorAvis}
                onClose={handleCloseErrorAvis}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Merci de saisir votre avis ?"}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleCloseErrorAvis} variant="contained"
                        color="primary">{t("BUTTON_FERMER")}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default StructureItemListFormation;
