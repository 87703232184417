import React, {useState, useEffect} from 'react';
import {
    Button,
    TextField,
    MenuItem,
    Autocomplete,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
} from '@mui/material';
import {useFetchWithToken} from '../../hooks/useFetchWithToken'; // Assurez-vous que le chemin d'importation est correct
import {useSystem} from '../../Context/System'; // Assurez-vous de corriger le chemin vers votre contexte
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import SearchModal from "../Search/SearchModal";
import useTexts from "../../hooks/useTexte";

const FormationForm = ({open, onClose, onAdd, LevelOptions, questionnaires , branchOptions, initialData}) => {
    const {provinces} = useSystem(); // Utilisation du contexte pour obtenir les provinces
    const {t} = useTexts()
    const [errorDate, setErrorDate] = useState(false);

    const [inputValueChef, setInputValueChef] = useState('');
    const fetchWithToken = useFetchWithToken();
    const [campMasters, setCampMasters] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);


    const [wish, setWish] = useState({
        date: '',
        function: '',
        level: '',
        branch: '',
        capacity:0,
        reserve_capacity:0,
        open_capacity:0,
        questionaire_id:0,
        mestre: null,
        section: '',
        nom: '',
        provinces: []
    });
    const handleBlurDate = (e) => {
        const inputValue = e.target.value;
        const formattedDate = dayjs(inputValue, 'DD/MM/YYYY', true).locale('fr');
        if (!formattedDate.isValid()) {
            setErrorDate(true);
        } else {
            setWish({ ...wish, date: formattedDate.format('YYYY-MM-DD') });
            setErrorDate(false);
        }
    };
    const handleSelectQuestionnaire = (event, newValue) => {
        setWish({ ...wish, questionaire_id: newValue.id });
    };
    useEffect(() => {
        if (inputValueChef) {
            handleSearch(inputValueChef);
        }
    }, [inputValueChef]);

    const handleSearch = async (searchTerm) => {
        setLoading(true);
        // Remplacez 'YOUR_API_URL' par l'URL de votre API
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/user/search`;
        const body = {lastname: searchTerm};
        const data = await fetchWithToken(apiUrl, 'POST', body);
        setCampMasters(data.chefs);
        setLoading(false);
    };





    useEffect(() => {
        if (initialData) {
            setWish({
                nom: initialData.nom || '',
                date: dayjs( initialData.date).tz('Europe/Paris') || '',
                function: initialData.function || '',
                capacity:initialData.capacity || 0,
                reserve_capacity:initialData.reserve_capacity || 0,
                open_capacity:initialData.open_capacity || 0,
                questionaire_id:initialData.questionaire_id || 0,
                level: initialData.level || '',
                branch: initialData.branch || '',
                mestre: initialData.mestre || null,
                section: initialData.section || 'scout',
                provinces: initialData.provinces || [],
                id:initialData.id || null,
                mestreData: initialData.mestreData || []
            });

        }
    }, [initialData]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const totalCapacity = parseInt(wish.capacity, 10);
        const reservedCapacity = parseInt(wish.reserve_capacity, 10);
        const openCapacity = parseInt(wish.open_capacity, 10);

        if (reservedCapacity + openCapacity > totalCapacity) {
            alert("Le nombre total de places (réservées + ouvertes) ne peut pas dépasser le nombre de places totales.");
            return;
        }


        onAdd(wish);
        onClose();
    };

    const filteredLevelOptions = LevelOptions.filter(levelOption =>
        levelOption.section.includes(wish.branch)
    );
    const handleDateChange = (newValue) => {
        if (newValue) {
            // Strip the time component
            const strippedDate = newValue.startOf('day');
            setWish({ ...wish, date: strippedDate.toISOString() });
        } else {
            setWish({ ...wish, date: null });
        }
    };
    const handleOpenSearchDialog = () => {
        setDialogOpen(true);
        handleSearch();
    };


    const handleSelectCampMaster = (chef) => {
        if (chef) {
            const { code_sycomore, firstname, lastname, id } = chef;
            const mestreData = { code_sycomore, firstname, lastname };

            setWish(prevWish => ({
                ...prevWish,  // Préservez toutes les autres valeurs de l'état
                mestre: id,
                mestreData
            }));
        } else {
        }
        setDialogOpen(false)
    };

    if (!provinces) {
        return <>Loading</>;
    }

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
            <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
                <DialogTitle id="form-dialog-title">{initialData && initialData.id ? "Modifier la Formation" : "Ajouter une Formation"}</DialogTitle>

                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                select
                                label={t("TEXT_COL_BRANCHE")}
                                value={wish.branch}
                                required={true}
                                variant="outlined"
                                fullWidth
                                inputProps={{readOnly: true}}
                            >
                                {branchOptions.map((option, index) => (
                                    <MenuItem key={index} value={option.key}>{option.label}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label={t("FIELD_NAME_FORMATION")}
                                value={wish.nom}
                                required={true}
                                variant="outlined"
                                fullWidth
                                inputProps={{readOnly: true}}
                            >

                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                select
                                label={t("TEXT_COL_LEVEL")}
                                value={wish.level}
                                inputProps={{readOnly: true}}
                                variant="outlined"
                                fullWidth
                            >
                                {filteredLevelOptions.map((option, index) => (
                                    <MenuItem key={index} value={option.key} >{option.label}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label={t("START_DATE")}
                                value={dayjs(wish.date).tz('Europe/Paris').format('dddd D MMM YYYY')} // Format personnalisé
                                inputProps={{readOnly: true}}
                                variant="outlined"
                                fullWidth
                            >
                                {filteredLevelOptions.map((option, index) => (
                                    <MenuItem key={index} value={option.key} >{option.label}</MenuItem>
                                ))}
                            </TextField>

                        </Grid>
                        <Grid item xs={12} md={6}>

                            <TextField
                                label={t("TEXT_COL_MESTRE")}
                                value={wish.mestre && wish.mestreData ? `${wish.mestreData.code_sycomore} ${wish.mestreData.firstname} - ${wish.mestreData.lastname}` : ''}
                                onClick={handleOpenSearchDialog}
                                readOnly
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                            />

                            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} aria-labelledby="search-dialog-title">



                                <DialogTitle id="search-dialog-title">{t("FIELD_MESTRE_CAMP")}</DialogTitle>
                                <DialogContent>
                                    <SearchModal onClick={handleSelectCampMaster}/>
                                </DialogContent>
                            </Dialog>



                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                options={provinces}
                                getOptionLabel={(option) => option.id_sycomore + " " + option.nom}
                                renderInput={(params) => <TextField {...params} label="Provinces" variant="outlined"
                                                                    fullWidth/>}
                                value={provinces.filter(province => wish.provinces.includes(province.id)) || []}
                                onChange={(event, newValue) => {
                                    setWish({...wish, provinces: newValue.map(option => option.id)});
                                }}
                            />
                        </Grid>


                        <Grid item xs={12} md={6}>
                            <TextField
                                label={t("FIELD_NBR_PLACE")}
                                type="number"
                                value={wish.capacity}
                                onChange={e => setWish({ ...wish, capacity: e.target.value })}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label={t("FIELD_NBR_OPEN_PLACE")}
                                type="number"
                                value={wish.open_capacity}
                                onChange={e => setWish({ ...wish, open_capacity: e.target.value })}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label={t("FIELD_NBR_RESEVERE_PLACE")}
                                type="number"
                                value={wish.reserve_capacity}
                                onChange={e => setWish({ ...wish, reserve_capacity: e.target.value })}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">{t("BUTTON_ANNULER")}</Button>
                    <Button type="submit" color="primary">{initialData && initialData.id ? t("BUTTON_EDIT_FORMATION"): t("BUTTON_ADD_FORMATION")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default FormationForm;
