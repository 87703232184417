import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {Box, CircularProgress} from '@mui/material';
import {useAuth} from './Context/AuthContext';
import PrivateRoute from "./composant/Core/PrivateRoute";
import FormationListPage from './Page/FormationListPage';
import FormationManagerListPage from './Page/FormationManagerListPage';
import FormationAdminListPage from './Page/FormationAdminListPage';
import LoginDemoPage from './Page/LoginDemoPage';
import FormBuilder from './Page/FormBuilder';
import Forms from './Page/Forms';
import KPIsPage from './Page/KPIsPage';
import RulesPage from './Page/rulesPage';
import Maitrise from './Page/Maitrise';
import TextEditor from "./Page/TextEditor";
import ErrorPage from "./Page/ErrorPage";
import FormationManagerPage from "./Page/FormationManagerPage";
import {useSystem} from "./Context/System";
import WishListPage from "./Page/WishListPage";
import LoginPage from "./Page/LoginPage";
import OAuthCallback from "./Page/OAuthCallback";
import ConfigEditor from "./Page/ConfigEditor";
import useRoles from "./hooks/useRoles";
import HomePage from "./Page/HomePage";
import DefaultLayout from './layouts/DefaultLayout';
import HomeAdminPage from "./Page/HomeAdminPage";
import {Email} from "@mui/icons-material";
import EmailPage from "./Page/EmailPage";
import useTexts from "./hooks/useTexte";
import ErrorModal from "./composant/Modal/ErrorModal";
import DerogationListPage from "./Page/DerogationListPage";
import AdminVoeuxListPage from "./Page/AdminVoeuxistPage";
import UserListPage from "./Page/UserListPage";
import Page404 from "./Page/page404";
import AgendaPage from "./Page/AgendaPage";
import VoeuxListPage from "./Page/VoeuxistPage";

function Main() {
    const {token, currentFunction, functionOptions, authErrorNoFunction} = useAuth();
    const roles = useRoles(currentFunction ? currentFunction.fonction : "");
    const {textes} = useSystem();
    const {t} = useTexts()


    if (!authErrorNoFunction) {
        if (textes.length < 1) {
            return <Box sx={{display: 'flex', justifyContent: 'center', p: 3}}><CircularProgress/></Box>;
        }
        if ((!currentFunction || !functionOptions || textes.length < 1 || functionOptions.length < 1) && token) {
            return <Box sx={{display: 'flex', justifyContent: 'center', p: 3}}><CircularProgress/></Box>;
        }

    }

    return (
        <Routes>

            <Route path="/adminthibault" element={<LoginDemoPage/>}/>


            <Route path="/login" element={<LoginPage/>}/>

            <Route path="/agenda" element={
                token ? (
                    <DefaultLayout>
                        <PrivateRoute>
                            <AgendaPage />
                        </PrivateRoute>
                    </DefaultLayout>
                ) : (
                    <AgendaPage />
                )
            } />
            <Route path="/auth/callback/:provider" element={<OAuthCallback/>}/>
            <Route path="/auth/:provider/callback" element={<OAuthCallback/>}/>

            <Route path="/" element={<DefaultLayout><PrivateRoute><HomePage/></PrivateRoute></DefaultLayout>}/>
            <Route path="/dash" element={<DefaultLayout><PrivateRoute><HomeAdminPage/></PrivateRoute></DefaultLayout>}/>
            <Route path="/email" element={<DefaultLayout><PrivateRoute><EmailPage/></PrivateRoute></DefaultLayout>}/>
            <Route path="/voeux" element={<DefaultLayout><PrivateRoute><WishListPage/></PrivateRoute></DefaultLayout>}/>
            <Route path="/formations"
                   element={<DefaultLayout><PrivateRoute><FormationListPage/></PrivateRoute></DefaultLayout>}/>
            <Route path="/texteditor"
                   element={<DefaultLayout><PrivateRoute><TextEditor/></PrivateRoute></DefaultLayout>}/>
            <Route path="/gerer-mes-ceps/:id"
                   element={<DefaultLayout><PrivateRoute><FormationManagerPage/></PrivateRoute></DefaultLayout>}/>
            <Route path="/gerer-mes-ceps"
                   element={<DefaultLayout><PrivateRoute><FormationManagerListPage/></PrivateRoute></DefaultLayout>}/>
            <Route path="/mes-maitrises"
                   element={<DefaultLayout><PrivateRoute><Maitrise/></PrivateRoute></DefaultLayout>}/>
            <Route path="/mes-maitrises/:id"
                   element={<DefaultLayout><PrivateRoute><Maitrise/></PrivateRoute></DefaultLayout>}/>

            <Route path="/forms" element={<DefaultLayout><PrivateRoute><Forms/></PrivateRoute></DefaultLayout>}/>
            <Route path="/gerer-derogation"
                   element={<DefaultLayout><PrivateRoute><DerogationListPage/></PrivateRoute></DefaultLayout>}/>
            <Route path="/voir-voeux"
                   element={<DefaultLayout><PrivateRoute><VoeuxListPage /></PrivateRoute></DefaultLayout>}/>

            <Route path="/gerer-voeux"
                   element={<DefaultLayout><PrivateRoute><AdminVoeuxListPage/></PrivateRoute></DefaultLayout>}/>
            <Route path="/gerer-utilisateur"
                   element={<DefaultLayout><PrivateRoute><UserListPage/></PrivateRoute></DefaultLayout>}/>


            <Route path="/gerer-ceps"
                   element={<DefaultLayout><PrivateRoute><FormationAdminListPage/></PrivateRoute></DefaultLayout>}/>
            <Route path="/configuration"
                   element={<DefaultLayout><PrivateRoute><ConfigEditor/></PrivateRoute></DefaultLayout>}/>
            <Route path="/creer-template"
                   element={<DefaultLayout><PrivateRoute><FormBuilder/></PrivateRoute></DefaultLayout>}/>
            <Route path="/creer-template/:id"
                   element={<DefaultLayout><PrivateRoute><FormBuilder/></PrivateRoute></DefaultLayout>}/>

            <Route path="/template" element={<DefaultLayout><PrivateRoute><Forms/></PrivateRoute></DefaultLayout>}/>

            <Route path="/rules" element={<DefaultLayout><PrivateRoute><RulesPage/></PrivateRoute></DefaultLayout>}/>
            <Route path="/states" element={<DefaultLayout><PrivateRoute><KPIsPage/></PrivateRoute></DefaultLayout>}/>

            <Route path="/error" element={<DefaultLayout><PrivateRoute><ErrorPage/></PrivateRoute></DefaultLayout>}/>

        </Routes>
    );
}

export default Main;
