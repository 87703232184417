import React, {useEffect, useState} from 'react';
import {
    Box,

    Button, Card, CardContent,
    CardMedia, Dialog, DialogContent,
    DialogTitle, Grid, Typography,

} from '@mui/material';
import {formatDate} from '../../Utils/functionTransforms';
import {useFetchWithToken} from "../../hooks/useFetchWithToken"

import useTexts from "../../hooks/useTexte";
import {LevelOptions} from "../../Utils/LevelOption";
import {branchOptions} from "../../Utils/BranchOption";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularIcon from '@mui/icons-material/Description';
import QuestionnaireIcon from '@mui/icons-material/ListAlt';
import {useConfirm} from "../../Provider/ConfirmProvider";
import DocumentCard from "../Core/DocumentCard";
import {useAuth} from "../../Context/AuthContext";
import {downloadDocument} from "../../Utils/downloadDocument";
import MaFiche from "../Fiche/Mafiche";


const FormationMangerItem = ({formation}) => {
    const fetchWithToken = useFetchWithToken();
    const {token,} = useAuth()

    const [openModalList, setOpenModalList] = useState(false);
    const [users, setUsers] = useState([]);
    const [keyModal, setKeyModal] = useState(0); // Utilisé pour forcer la recréation de FormBuilder
    const [initialFormData, setInitialFormData] = useState(null);
    const [openFormModal, setOpenFormModal] = useState(false);
    const [openDocumentsModal, setOpenDocumentsModal] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [hasUnsavedChangesFiche, setHasUnsavedChangesFiche] = useState(false);
    const {requestConfirm} = useConfirm(); // Utiliser le hook pour obtenir la fonction de demande de confirmation

    const {t} = useTexts();
    const statusIcons = {
        "open": {"icon": <CheckCircleIcon color="success"/>, label: t("OPEN_FORMATION_LABEL")},
        "draft": {"icon": <CheckCircleIcon color="warning"/>, label: t("PROJET_FORMATION_LABEL")},

    };

    const handleCloseFormModal = () => {

        if (hasUnsavedChangesFiche) {
            requestConfirm(t("MODIFICATION_LOST_WARNING"), () => {
                setOpenFormModal(false);
            });
        } else {
            setOpenFormModal(false);
        }
        setHasUnsavedChangesFiche(false)


    };
    const handleCloseDocumentsModal = () => {
        setOpenDocumentsModal(false);
    };
    const handleOpenDocumentsModal = async () => {
        const apiUrlDocuments = `${process.env.REACT_APP_API_URL}/api/form/voeu/${formation.id}/docs`;
        try {
            const data = await fetchWithToken(apiUrlDocuments);
            setDocuments(data);
            setOpenDocumentsModal(true);
        } catch (error) {
            console.error("Error fetching documents:", error);
        }
    };
    const handleOpenFormModal = (id) => {
        setKeyModal(keyModal + 1)
        setInitialFormData(id);
        setOpenFormModal(true);
    };


    const handleDownloadDocument = (filename) => {
        downloadDocument(filename, token, {"type": "mestre", "formationId": formation.id})

    };


    const level = LevelOptions.find((branch) => formation.level === branch.key)
    const branch = branchOptions.find((branch) => formation.branch === branch.key)


    useEffect(() => {
        //loadFormation();
        // listUserSelecing();
    }, []); // Le tableau vide assure que l'effet s'exécute une seule fois au montage
    return (
        <>
            <Card sx={{
                maxWidth: 345,
                margin: 2,
                textAlign: 'center',
                border: '2px solid #ccc',
                borderRadius: '10px',
                overflow: 'visible'
            }}>
                <CardMedia
                    component="img"
                    sx={{width: '80px', height: '80px', margin: '-25px auto 10px', zIndex: 1, position: 'relative'}}
                    image={branch.logo}

                />
                <CardContent>
                    <Typography sx={{fontFamily: 'Ubuntu', fontWeight: "bold", fontSize: "30px"}} gutterBottom
                                variant="h5" component="div">{level.label}</Typography>
                    <Typography sx={{fontFamily: 'Ubuntu', fontWeight: "bold", fontSize: "24px"}} variant="h6"
                                component="div">{formation.nom}</Typography>
                    <Typography sx={{fontFamily: 'Ubuntu'}} variant="body1" color="text.secondary">

                        <div>du {formatDate(formation.date)}</div>
                        <div>au {formatDate(formation.end_date)}</div>
                    </Typography>


                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px', textAlign: "left"}}>
                        <Button variant="contained" startIcon={<CircularIcon/>} sx={{width: '100%'}} onClick={handleOpenDocumentsModal}>{t("DOC_STAGIAIRE")}</Button>
                        {formation.open_questionaire == 1 && (
                            <Button variant="contained" onClick={() => handleOpenFormModal(formation.questionaire_id)}
                                    startIcon={<QuestionnaireIcon/>} sx={{width: '100%'}}>{t("QUESTIONAIRE_STAGIAIRE")}</Button>
                        )}
                    </Box>
                </CardContent>

            </Card>
            <Dialog open={openDocumentsModal} onClose={handleCloseDocumentsModal} maxWidth="lg" fullWidth>
                <DialogTitle>Documents</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {documents.map((doc) => (
                            <Grid item xs={12} sm={6} md={4} key={doc.id}>
                                <DocumentCard document={doc}
                                              onDownload={() => handleDownloadDocument(doc.path)}/>
                            </Grid>
                        ))}
                    </Grid>

                </DialogContent>
            </Dialog>

            <Dialog open={openFormModal} onClose={handleCloseFormModal} maxWidth="lg" fullWidth>
                <DialogTitle>Modifier la Fiche</DialogTitle>
                <DialogContent>
                    <MaFiche id={formation.id} onClose={handleCloseFormModal}/>
                </DialogContent>
            </Dialog>


        </>
    )
        ;
};

export default FormationMangerItem;
